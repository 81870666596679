.time-zone-picker {
  width: 100%;
  position: relative;
  text-align: left;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
  }

  .time-zones-list {
    z-index: 1000;
    width: 100%;
    position: absolute;
    overflow: hidden;
    box-shadow: 0 0 1px rgba(86,96,117,.7);
    border-radius: 3px;
    
    ul {
      padding: 0;
      background-color: #fff;
      min-width: 200px;
      max-height: 360px;
      overflow-y: scroll;
      overflow-x: hidden;
      border: none;
      font-size: 12px;
      list-style: none outside none;
      text-shadow: none;
      margin: 0;

      .divider {
        border-bottom: 1px solid @ninja-light;
      }

      .show-more-locations {
        border-top: 1px solid @ninja-light;
        border-bottom: 1px solid @ninja-light;
      }

      li {
        display: flex;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        padding: 15px;
    
        &:hover {
          background-color: @ninja-white;
        }

        .current-location-marker {
          position: absolute;
          margin-left: 3px;
        }
      }
    }
  }
}