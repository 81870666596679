:root {
  font-family: "Inter", sans-serif;
  font-size: initial;
  font-feature-settings: "cv05";
}

body {
  font-family: inherit;
  color: @ninja-dark;
  font-size: 13px;
  min-width: 1024px;
}

ul, ol {
  list-style: none outside none;
  margin-left: 0;
}

#wrapper {
  height: 100vh;
  overflow: hidden;
}

#application {
  display: flex;
  flex:  1;
}

#page-wrapper {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#main-container {
  flex: 1;
  display: flex;
}

.full-screen-mode{
  // max-width: 1280px !important;
  #page-wrapper {
    width: 100%;
  }
  #main-container{
    top: 0px;
    bottom: 0px;
    #widget-grid{
      height: 100% !important;
    }
  }
  #footer,
  #application-sidebar,
  #application-topbar {
    display: none;
  }
}
