.dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  .ReactVirtualized__Table__headerRow {
    overflow: initial !important;
  }
  .horizontal-tiles .horizontal-tile hgroup {
    margin-left: 0;
  }
  #widget-grid{
    flex: 1;
    display: flex;
		overflow: hidden;

		&.pad-right{
			padding-right: 5px;
		}

		> .row {
			height: 100%;
			padding-left: 10px;
			padding-right: 10px;
		}

    #device-overview {
      .icon.info {
          color: @ninja-blue;
      }
      .icon > img {
          margin: 0 0 0 3px;
          height: 19px;
          width: 19px;
      }
    }
    #customer-overview,
    #system-overview {
      #customer-overview-react-container {
        width: 100%;
      };
      flex: 1;
      .node-list {
        display: flex;
        flex-direction: column;
        flex: .36;
      }
      .dashboard-info {
        display: flex;
        flex: .64;
        overflow-y: auto;
      }
    }
	}

    .backup-stat {
      margin: 10px 0px 10px 0px;
    }

    .panel {
      margin-bottom: 10px;

      .panel-heading {
        padding: 5px 10px 7px 12px;
      }

      .text-large {
        font-size: 1.1em;
        font-weight: 600;
      }
    }

	h4.heading{
		font-weight: 600;
		margin-bottom: 2px;
	}

    .node-list {
      .filter {
        color: @gray-light;
        cursor: pointer;
        font-size: 13.5px;

        &:hover {
          color: @gray;
        }

        &.active {
          font-weight: 600;
          color: @gray;
        }
      }

      .horizontal-tiles{

        .horizontal-tile{
          border: 0;
        }

        strong{
          color: black;
        }

        .e-folder-icon{
            display: inline;
            width: 12px;
        }
      }
    }

	.system-health{
    #bar-chart text[text-anchor="middle"]{
      opacity: 0;
    }
    .overall-health-counts {
      padding: 10px 15px;
      margin: -20px 0 25px 0;
    }
    .horizontal-tile{
      border-top: 1px solid @table-border-color;
      padding-top: 10px;
      border-bottom: none;
      height: 50px;
    }
  }

    .lists{
        display: flex;
        flex-direction: column;
        padding: 0;

        .panel {
            ul {
                padding: 10px 0 0 15px;
            }

            .label {
              line-height: 14px;
              width: 32px;
              margin-right: 7px;
              background: @gray-light;
              color: white;
            }
        }
    }

	.donut-widget{
		padding: 0px;

		.chart{
      width: 165px;
      height: 200px;
      margin: -10px auto 0 auto;
		}

		.color-codes{
      margin-left: -20px;

			.color-code-container{
        margin-top: 35px;

        > div {
          line-height: 20px;
          margin-bottom: 5px;

          .color-code{
            display: inline-block;
            height: 18px;
            width: 18px;
            margin-right: 4px;
            border: 1px solid @table-border-color;

            &.danger{
              background: @brand-danger;
            }

            &.success{
              background: @brand-success;
            }

            &.warning{
              background: @brand-warning;
            }

            &.action{
              background: @ninja-yellow;
            }

            &.antivirus{
              background: @purple;
            }

            &.patch-management{
              background: @pink;
            }

            &.team-viewer{
              background: @brand-info;
            }
          }

          .color-code-label{
            position: relative;
            top: -5px;
            left: 1px;
            font-size: 0.8em;
            font-weight: 600;
          }
        }
        }
		}
  }
  
  .tooltip-location-description {
    margin-left: 5px;
  }
}

#entity-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  .entity-cell {
    padding-left: 0px !important;
  }
  .entity-list-toolbar {
    display: inherit;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    > div {
      display: inherit;
      align-items: center;
    }
  }
  .horizontal-tile {
    padding: 3px 6px 3px 0;
    height: 60px;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    > div {
      padding: 0;
      height: 100%;
      padding: 2px 0px;
      > hgroup {
        height: inherit;
        display: flex;
        flex-direction: column;
        margin-left: 6px;
        padding-right: 0px;
        > div {
          flex: 1;
          display: inherit;
          align-items: center;
          &:first-child {
            > h4 {
              .text-ellipsis;
              margin: 0 auto 0 0;
              min-height: 15px;
              line-height: 1.3;
            }
          }
          &:last-child {
            > small {
              margin: 0 auto 0 0;
            }
          }
        }
      }
    }
  }
}

.list-actions {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  min-height: 20px;
}

.col-checkbox {
  width: 40px;
  & ~ hgroup {
    line-height: 1;
    margin-left: 0 !important;
  }
}

.vendor-logo {
  margin-right: 10px;
}

.status-icons svg {
  &:not([color]) {
    color: @ninja-light;
    &.success {
      color: @green;
    }
    &.warning {
      color: @yellow;
    }
    &.danger {
      color: @red;
    }
  }
  
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.dashboard-pie-chart-no-running-actions {
  box-sizing: border-box;
  text-align: center;
  padding: 30px;
}

.change-location-modal-container {
  display: flex;
  flex-flow: column;

  .selected-devices-list {
    display: flex;
    flex-flow: column;
    margin: 20px 0 0 0;

    label {
      text-align: left;
      margin-left: 20px;
    }

    ul {
      text-align: left;
      padding-left: 35px;
      list-style-type: disc;
      margin: 0;
      max-height: 200px; 
      overflow-y: auto;
    }
  }
}
