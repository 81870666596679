.data-fetching-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 18px;
  color: @ninja-medium;
  background-color: rgba(255, 255, 255, 0.7);
}

.file-folder-browser {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;

  .server-icon {
    font-size: 18px;
    margin-left: 5px;
  }

  .folders-icon {
    width: 25px;
    font-size: 20px;
    color: @ninja-blue-saturated;
  }

  .folder-icon {
    font-size: 20px;
    color: @ninja-yellow;
  }

  .file-icon {
    font-size: 18px;
    color: @ninja-medium;
  }

  .folder-path-name {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0px;
  }

  .disabled-dir {
    color: @ninja-light !important;
  }

  .ReactVirtualized__Table__headerRow {
    display: flex;
    align-items: center;
    
    .ReactVirtualized__Table__headerColumn {
      &:first-child {
        margin-left: 5px;
      }
      margin-right: 10px;
    }
  }
  
  .file-browser-table-row:not(.ReactVirtualized__Table__headerRow) {
    display: flex;
    align-items: center;

    .ReactVirtualized__Table__headerColumn,
    .ReactVirtualized__Table__rowColumn:last-child {
      margin: 0;
    }
    
    .file-browser-table-cell {
      margin: 0 10px 0 0;
      padding-left: 5px;
      display: flex;
      align-items: center;
      user-select: none;
      height: inherit;

      &.file-browser-table-cell-checkbox {
        background-color: #FFFFFF;
      }
    }

    &.active-row {
      .file-browser-table-cell.file-browser-table-cell-checkbox:before {
        content: "";
        position: absolute;
        left: 0;
        width: 32px;
        height: 32px;
        border-radius: 3px;
        background-color: @ninja-light;
      }

      .file-browser-table-cell.file-browser-table-cell-checkbox {
        .icheckbox_square-blue {
          background-color: white;
        }
      }
    }

    &.checked {
      background-color: @ninja-light-blue !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid @border-color;
    }

    &:hover {
      cursor: pointer;
      background-color: @ninja-white;
    }

    &:active, &:focus {
      outline: none !important;
    }
  }
}

.file-folder-actions {
  flex: 0 0 250px;
  margin-left: 40px;
  padding: 0px 10px;

  .file-folder-actions-debounced-search {
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

#device-backups {
  margin: 0 !important;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;

  .restore-manager-content {
    padding: 20px 10px 20px 20px;
    height: calc(~"100% - 50px");
    display: flex;
  }
}
