/*!
 * jQuery UI Bootstrap v1.0 Alpha
 *
 * jQuery UI spinner 1.10.3
 * http://docs.jquery.com/UI/Menu#theming
 *
 * Portions copyright Addy Osmani, jQuery UI & Twitter Bootstrap
 * Created the LESS version by @dharapvj
 * Released under MIT
 */

@spinner-height: 30px;

.ui-spinner {
	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 0;
	vertical-align: middle;
}

.ui-spinner-input {
	border: none;
	background: none;
	padding: 0;
	margin: .2em 22px 0.2em 0.4em;
	vertical-align: middle;
}

.ui-spinner-button {
	width: 16px;
	height: 50%;
	font-size: .5em;
	padding: 0;
	margin: 0;
	text-align: center;
	position: absolute;
	cursor: default;
	display: block;
	overflow: hidden;
	right: 0;
}

/* more specificity required here to overide default borders */
.ui-spinner {
	a.ui-spinner-button {
		border-top: none;
		border-bottom: none;
		border-right: none;
	}
	/* vertical centre icon */
	.ui-icon {
		position: absolute;
		margin-top: -8px;
		top: 50%;
		left: 0;
	}
	/* need to fix icons sprite */
	.ui-icon-triangle-1-s {
		background-position: -65px -16px;
	}
}
	
.ui-spinner-up {
	top: 0;
}

.ui-spinner-down {
	bottom: 0;
}

/*
 * JQUI SPINNERS
 */

.ui-spinner-input {
	margin: 0 !important;
	height: @spinner-height;
	padding: 6px 24px 6px 12px;
	text-align: center;
}

.ui-spinner-input.spinner-left {
	padding:6px 12px 6px 24px;
}

.ui-spinner {
	width:100%;
}

.ui-spinner-input.spinner-left + .ui-spinner-button, .ui-spinner-input.spinner-left + .ui-spinner-button + .ui-spinner-button{
	right:auto;
	left:0px;
}
	
.ui-spinner-up, .ui-spinner-down {
	background: @brand-success;
	border-radius: 0px;
}
.ui-spinner-up:hover, .ui-spinner-up:active, .ui-spinner-up:focus {
	background: darken(@brand-success, 10%);
}
.ui-spinner-down:hover, .ui-spinner-down:active, .ui-spinner-down:focus {
	background: darken(@brand-danger, 10%);
}

.ui-spinner-down {
	background: lighten(@brand-danger, 5%);
}

.ui-spinner-up >:first-child, .ui-spinner-down >:first-child {
	display:none;	
}


.ui-spinner-button {
width:19px;
}

.ui-widget-content .ui-icon {
background:none !important;
}

/* spinner both */

.ui-spinner-input.spinner-both {
	padding:6px 30px;
}

.ui-spinner-input.spinner-both + .ui-spinner-button + .ui-spinner-button {
	left:0px;
	right:auto;
}
.ui-spinner-input.spinner-both + .ui-spinner-button, 
.ui-spinner-input.spinner-both + .ui-spinner-button + .ui-spinner-button {
	height: @spinner-height;
	width: 25px;
}

.ui-spinner-input.spinner-both + .ui-spinner-up:before, .ui-spinner-input.spinner-both + .ui-spinner-up + .ui-spinner-down:before {
	margin-top:8px;
}

.ui-spinner{
	min-width: 90px;
	width: 90px;
  height: @spinner-height;
	input[disabled] ~ a{
			pointer-events: none;
	}
}

.ui-spinner-up, .ui-spinner-down{
	background-color: #bfbfbf !important;

	&:hover{
		background-color: #bfbfbf !important;
	}
}

.ui-spinner-up:before, .ui-spinner-up:after, .ui-spinner-down:before {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 14px;
	
	background: #fff;
	content: "";
	height: 5px;
	width: 15px;
	position: absolute;
	margin-left: -30% !important;
	margin-top: 50% !important;
	border-radius: 2px;
}

.ui-spinner-up:after {
	transform: rotate(90deg);
}
