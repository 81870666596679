.configuration-integrations {
  .panel .horizontal-tile:not(:first-child) {
    border-top: 1px solid @border-color;
  }
    .teamviewer {
        .teamviewer-container {
            border: 1px solid @table-border-color;
            .row:not(.teamviewer-container-actions) > p {
                margin: 0;
                padding: 10px;
                &:first-of-type {
                    border-right: 1px solid @table-border-color;
                }
            }
            .teamviewer-container-actions {
                border-top: 1px solid @table-border-color;
                padding: 20px 0;
                text-align: center;
            }
        }
    }

  #splashtop-configuration {
    .client { padding: 5px; }

    .modal-content { width: 800px; }
    .modal-body { overflow: auto; min-height: 100px; max-height: 500px; }

    label {
      span {
        font-weight: normal;
        padding-left: 10px;
      }
    }

    .client {
      margin-left: 30px;
    }

    .client-name {
      margin-left: 10px;
    }
  }

  #webroot-configuration {
    #webroot-mapping-table {
      .ReactVirtualized__Table__headerColumn {
        padding: 0 15px;
        &:first-child {
          padding: 0 15px 0 0;
        }
      }
      .table-row {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex: 1 1 1px;
          > li {
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 0 15px;
            &:first-child {
              flex: 0.75 1 1px;
            }
            &:nth-of-type(2) {
              flex: 1 1 1px;
            }
            &:last-child {
              text-align: right;
              flex: 0.25 1 1px;
            }
            > span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              flex: 1 1 1px;
              > i {
                color: @ninja-light;
              }
            }
          }
        }
      }
    }
  }
}
