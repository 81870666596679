#wrapper > div:not(.visibility-hidden) {
  height: 100vh;
}

#divRegister, #divResetPasswordRequest, #divResetPassword, #divLogin, #divRequestActivation {
  overflow-y: auto;
  height: 100%;
}

div.loginscreen {
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: auto;

  div.logo{
    margin-bottom: 30px;
    > a {
      display: block;
      text-align: center;
      img {
        max-width: 60%;
        max-height: 60%;
      }
    }
  }

  label.input {
    font-weight: 500;
    display: block;
  }

  form {
    width: 100%;
    .intl-tel-input {
      width: 100%;
    }
    .checkbox {
      text-align: left;
      .text-muted {
        padding-left: 10px;
      }
    }
    div.success {
      color: @ninja-green;
      margin-left: 15px;
      margin-top: 20px;
    }
    div.error {
      margin-left: 15px;
      margin-top: 20px;
      color: red;
    }
  }
}

#divLogin {
  .info {
    color: white;
  }
}

#reset-password-form {
  label.input {
    small {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.reset-password-request-form {
  .radio label {
    padding-left: 0px;
  }
}

#divMaintenance {
  .loginscreen.middle-box {
    width: 500px;
  }
  .info {
    color: @gray-mid-light;
    > span {
      white-space: pre-line;
    }
  }
}

div.register-checkbox {
  span {
    color: @ninja-blue-saturated;
  }
}

#reset-password-link,
#register-link,
#login-link {
  font-weight: 600;
  color: @ninja-blue-saturated;
  &:hover {
    color: @ninja-blue;
  }
}

#login-form {
  .checkbox {
    margin-top: 0;
  }
}

.no-auth {
  overflow-x: hidden;
}

.no-auth-container {
  box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
  width: 400px;

  .keep-sign-in-text {
    font-style: italic;
  }

  .no-auth-input {
    border: 1px solid @ninja-light;
  }

  .no-auth-gray-text {
    color: @gray-light;
  }
}

.no-auth-parent-container {
  small.copyright {
    width: 100%;
    left: 0;
    bottom: 5px;
    position: absolute;
    text-align: center;
    color: @light-grey;
    min-width: 1024px;
  }
}
