.activity-list {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  flex: 1;
  .tz {
    display: flex;
    justify-content: flex-end;
  }
  .activity-loader {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > div > div {
      color: @ninja-dark !important;
      font-size: 1.25em !important;
      font-weight: 500;
    }
  }
  .sub {
    word-break: break-word;
    color: @ninja-medium;
    font-size: 12px;
    font-weight: 400;
    &.time {
      font-size: 11px;
    }
  }

  .ReactVirtualized__List {
    .activity {
      transition: .3s;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      font-size: 12px;
      background-color: #FFFFFF;
      border-bottom: 1px solid @border-color;
      &.new {
        background-color: @ninja-white;
        border-color: @ninja-light;
      }
      &.selectable {
        cursor: pointer;

        &:hover {
          background-color: @ninja-white;
        }
      }
      .read-only &.selectable {
        pointer-events: none !important;
      }
      &:last-child {
        border-bottom: none !important;
      }
      b {
        .text-ellipsis;
      }
      p {
        margin: 0;
      }
      > div {
        color: @ninja-dark;
        padding: 0 5px;
        > div {
          display: flex;
          justify-content: space-between;
          b {
            font-weight: 600;
            flex: .9 1;
          }
        }
        > *:empty {
          display: none;
        }
      }
    }
  }
}
