/* DATATABLES */

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

.dataTables_wrapper {
  padding-bottom: 30px;
}

.dataTables_length {
  float: left;
}

.dataTables_filter label {
  margin-right: 5px;
}

.html5buttons {
  float: right;
}

.html5buttons a {
  border: 1px solid @ninja-light;
  background: #fff;
  color: @ninja-medium;
  box-shadow: none;
  padding: 6px 8px;
  font-size: 12px;

  &:hover, &:focus:active {
    background-color: @ninja-light;
    color: inherit;
    border-color: @ninja-medium;
  }
}

div.dt-button-info {
  z-index: 100;
}

@media (max-width: 768px) {
  .html5buttons {
    float: none;
    margin-top: 10px;
  }

  .dataTables_length {
    float: none;
  }
}


.custom-data-table-container{
	header h4{
        padding-left: 2px;
        color: black;
        font-size: 1.1em;
        font-weight: 400;
    }
}

.custom-data-table{
	.header-row{
		margin-top: -35px;
		padding-right: 20px;

		> div.float-right{
			padding-left: 20px;

			.dataTables_length select{
				margin-right: 5px;
			}

			.dataTables_filter input{
				margin-left: 5px;
			}
		}
	}

    .actionable-td{
        overflow: visible;
        span.action-buttons.dropdown-button{
            position: relative;
            float: right;
        }

        ul.table-inline-menu.dropdown-menu.vertical-dropdown-menu{
            left: -150px;
            top: 7px;
        }
    }

	td{
		.text-ellipsis;
		max-height: 35px;
		max-width: 100px;
	}

    tr{
        &.expanded td{
		    white-space: normal;
		    word-break: break-all;
	    }

        .table-inline-icon {
            opacity: 0;
            float: right;
            position: relative;
            top: -5px;
            font-size: 1.2em;
            margin-right: 5px;
        }

        &:hover .table-inline-icon {
            opacity: 1;
        }

        .table-inline-menu{
        	li a {
        		width: 160px;
        		padding-left: 15px;
        	}
        }
    }

	thead th{
		padding: 15px 10px 15px 10px !important;
		font-weight: 600;
		color: black;

		&:not(.text-left){
			text-align: center;
		}

		&:after{
			padding-left: 10px;
		}
	}

	tbody td:not(.text-left):not(.text-right){
		text-align: center;
	}

	&.no-header{
		.header-row{
			display: none;
		}
	}

	&.no-footer{
		.footer-row{
			display: none;
		}
	}

	.dataTables_empty{
		text-align: center;
	}
}
