#customer-editor {
  .custom-schedule {
    flex-direction: column;
  }

  textarea {
    max-height: 250px;
  }

  .row.alerting {
    min-height: 148px;
  }

  h4 {
    padding-top: 5px;
  }

  .splashtop-option {
    font-size: 16px;
    padding-left: 10px;
  }

  .splashtop-option-caption {
    color: @ninja-medium;
    padding: 0px 0px 10px 37px;
    width: 460px;
  }

  .splashtop-option-managed {
    padding: 0px 0px 10px 37px;
  }

  .splashtop-option-managed select.form-control {
    width: 500px;
  }

  .splashtop-error {
    margin-top: 10px;
    color: red;
  }

  .cloudberry-option {
    font-size: 16px;
    padding-left: 0px;
  }

  .cloudberry-option-caption {
    color: @ninja-medium;
    padding: 0px 0px 10px 45px;
  }

  .cloudberry-error {
    margin-top: 10px;
    color: red;
  }

  .tab-links {
    margin: 0 0 26px 0;
    padding: 0;
    display: flex;
    > li {
      margin-left: 15px;
      color: @ninja-medium;
      
      > span {
        cursor: pointer;
      }
      &.active {
        position: relative;
        color: @ninja-black;
        > span:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: -6px;
          left: 0;
          height: 2px;
          border-radius: 2px;
          background-color: @ninja-dark;
        }
      }
    }
  }
}

.locations-editor-tab {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin-top: 15px;

  .table-header {
    display: flex;
    justify-content: flex-end;
  }

  .ReactVirtualized__Grid {
    background-color: #ffffff;
    max-height: 500px;
    overflow-y: scroll !important;
  }

  .cursor-default {
    cursor: default !important;
  }

  h4 {
    margin: 50px 0 0 100px;
  }
}

.default-credential-row {
  display: flex;
  align-items: center;
  margin: 0 15px;
  padding: 15px 0;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:first-of-type {
    padding-top: 2px;
  }
}

.node-approval-tabs {
  padding: 0px 15px 15px 15px;
  height: 100%;

  .node-approval-tabs-content {
    flex: 1;
    height: calc(100% - 35px);
    min-width: 500px;
    padding: 0 15px;

    .node-approvals-general-tab {
      height: 100%;

      .radio-buttons-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        label {
          margin: 10px 0px;
          font-weight: bold;
          font-size: 14px;

          span {
            margin-left: 10px;
          }

          p {
            font-size: 12px;
            font-weight: normal;
            margin-left: 33px;
          }
        }
      }
    }

    .nodes-sort {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .status-tab {
      display: flex;
      flex-direction: column;
      height: 100%;

      &.nodes-table-container {
        flex: 1;

        .ReactVirtualized__Table__headerRow {
          overflow: visible !important;

          .selected-qty {
            font-weight: normal;
          }
        }

        .nodes-table-column {
          flex: 1;
          height: 100%;
          cursor: pointer;
        }
      }
    }   
  }
}
