#image-manager-wizard-modal {

    .logo img{
        width: 48px;
        height: 48px;
    }

    .editor-name{
    	padding-left: 10px;
    	font-size: 1.4em;
    }

    .content-container{
        height: calc(~"100% - 150px") !important;
    }

    header{

		padding-top: 6px;

		&:not(.has-steps){
			margin-bottom: 50px;
		}

		.menu-buttons{
			margin-top: 15px;
		}

		.steps{
			margin-top: 30px;

			ul{
                padding-left: 0;
				text-align: center;
				list-style-type: none;

				li{
					display: inline-block;

					.progress{
						height: 0.8em;
						margin: 0 5px;
						width: 125px;

						.progress-bar{
							width: 100%;
						}
					}

					.step-label{
						display: inline;
						line-height: 2em;
						font-size: 1.1em;

						&.active{
							color: black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}

    .form-group{
        .icheckbox_square-green{
            margin-top: 4px;
        }
    }
    
    .iCheck-helper {
        width: inherit !important;
        margin-left: 15px !important;
    }
}

#image-manager-locations-list, #image-manager-location, #image-manager-targets-list, #image-manager-target, #image-manager-agent-settings{

    img{
        width: 48px;
        height: 48px;
        margin-right: 10px;
    }

    .form-group{
    	margin-bottom: 5px;

    	label.select{
    		width: 100%;
    	}

    	em.invalid{
    		margin-top: 0px;
    		padding-bottom: 3px;
    	}

        .checkbox, .spinner{
            text-align: left;
            padding-top: 7px;
        }
    }
}

#image-manager-locations-list, #image-manager-targets-list{

    .modal-body{
    	height: 200px;

    	.horizontal-tiles{
	    	padding-top: 10px;

	    	.horizontal-tile{
		    	text-align: left;
		    }
	    }
    }
}