/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    background: data-uri('green.png') no-repeat;
    border: none;
    cursor: pointer;
}

// As strange as it may appear, we need to add all background propeties below, not just the background-image
.i-checks-blue{
    .icheckbox_square-green, .iradio_square-green {
        background: data-uri('blue.png') no-repeat;

        &.icheckbox_square-green {
            background-position: 0 0;
        }
        &.icheckbox_square-green.hover {
            background-position: -24px 0;
        }
        &.icheckbox_square-green.checked {
            background-position: -48px 0;
        }
        &.icheckbox_square-green.disabled {
            background-position: -72px 0;
            cursor: default;
        }
        &.icheckbox_square-green.checked.disabled {
            background-position: -96px 0;
        }

        &.iradio_square-green {
            background-position: -120px 0;
        }
        &.iradio_square-green.hover {
            background-position: -144px 0;
        }
        &.iradio_square-green.checked {
            background-position: -168px 0;
        }
        &.iradio_square-green.disabled {
            background-position: -192px 0;
            cursor: default;
        }
        &.iradio_square-green.checked.disabled {
            background-position: -216px 0;
        }
    }
}

.icheckbox_square-green {
    background-position: 0 0;
}
.icheckbox_square-green.hover {
    background-position: -24px 0;
}
.icheckbox_square-green.checked {
    background-position: -48px 0;
}
.icheckbox_square-green.disabled {
    background-position: -72px 0;
    cursor: default;
}
.icheckbox_square-green.checked.disabled {
    background-position: -96px 0;
}

.iradio_square-green {
    background-position: -120px 0;
}
.iradio_square-green.hover {
    background-position: -144px 0;
}
.iradio_square-green.checked {
    background-position: -168px 0;
}
.iradio_square-green.disabled {
    background-position: -192px 0;
    cursor: default;
}
.iradio_square-green.checked.disabled {
    background-position: -216px 0;
}

.icheckbox_minimal,
.iradio_minimal {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: data-uri('minimal.png') no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal {
    background-position: 0 0;
}
    .icheckbox_minimal.hover {
        background-position: -20px 0;
    }
    .icheckbox_minimal.checked {
        background-position: -40px 0;
    }
    .icheckbox_minimal.disabled {
        background-position: -60px 0;
        cursor: default;
    }
    .icheckbox_minimal.checked.disabled {
        background-position: -80px 0;
    }

.iradio_minimal {
    background-position: -100px 0;
}
    .iradio_minimal.hover {
        background-position: -120px 0;
    }
    .iradio_minimal.checked {
        background-position: -140px 0;
    }
    .iradio_minimal.disabled {
        background-position: -160px 0;
        cursor: default;
    }
    .iradio_minimal.checked.disabled {
        background-position: -180px 0;
    }


/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx) {
    .icheckbox_minimal,
    .iradio_minimal {
        background-image: data-uri('minimal@2x.png');
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }

    :not(.i-checks-blue){
        .icheckbox_square-green,
        .iradio_square-green {
            background-image: data-uri('green@2x.png');
            -webkit-background-size: 240px 24px;
            background-size: 240px 24px;
        }
    }

    // As strange as it may appear, we need to add all background propeties below, not just the background-image
    .i-checks-blue{
        .icheckbox_square-green, .iradio_square-green {
            background-image: data-uri('blue@2x.png');
            -webkit-background-size: 240px 24px;
            background-size: 240px 24px;

            &.icheckbox_square-green {
                background-position: 0 0;
            }
            &.icheckbox_square-green.hover {
                background-position: -24px 0;
            }
            &.icheckbox_square-green.checked {
                background-position: -48px 0;
            }
            &.icheckbox_square-green.disabled {
                background-position: -72px 0;
                cursor: default;
            }
            &.icheckbox_square-green.checked.disabled {
                background-position: -96px 0;
            }

            &.iradio_square-green {
                background-position: -120px 0;
            }
            &.iradio_square-green.hover {
                background-position: -144px 0;
            }
            &.iradio_square-green.checked {
                background-position: -168px 0;
            }
            &.iradio_square-green.disabled {
                background-position: -192px 0;
                cursor: default;
            }
            &.iradio_square-green.checked.disabled {
                background-position: -216px 0;
            }
        }
    }
}

.form-iradio-wrapper {
    > div {
        padding-top: 7px;
        label {
            margin-right: 15px;

            span {
                padding-left: 5px;
            }
        }

        label:nth-child(2) {
            text-align: right;
        }
    }
}

.form-iradio-wrapper-with-decription {
    .iradio-option {
        cursor: pointer;
    }

    .option-name {
        font-size: 16px;
        padding-left: 0px;
        margin: 10px 0 10px;
        font-weight: 400;
        font-family: Open Sans,sans-serif;
        color: #616264;
    }

    .option-caption {
        color: @ninja-medium;
        padding: 0px 0px 10px;
        font-weight: 400;
    }


    label {
        display: flex;

        .iradio_square-blue {
            width: 23px;
            margin-right: 20px;
            flex-shrink: 0;
        }
    }
    
}