#nms-net-flow-content{
    #net-flow {
        height: 100%;
        overflow: hidden;
        padding: 10px 20px 0px 10px;

        ul{
            margin: 0;

            li{
                cursor: pointer;
                display: inline-block;

                span {
                    font-size: 11px;
                    font-weight: 500;
                    color: @gray-sub-light;
                }

                &:hover{
                    span {
                        color: black;
                    }
                }

                &.active{
                    span {
                        color: black;
                        font-weight: 600;
                    }
                }
            }
        }

        .total-traffic{
            padding-left: 15px;
            font-size: 10px;

            .refresh{
                font-size: 11px;
                margin-top: 3px;
                margin-bottom: 8px;
            }
        }

        .left-panel{
            width: 20% !important;
            height: 100%;
            padding-right: 20px;

            .categories{
                padding: 0;
                height: calc(~"100% - 60px");

                ul{
                    height: 100%;
                    padding-top: 12px;
                    padding-left: 32px;
                    overflow-y: auto;

                    li{
                        display: list-item;
                        padding-bottom: 5px;

                        .sub-categories{
                            height: 0px;
                            max-height: 0px;
                            padding-left: 17px;
                            padding-top: 0;
                            overflow: hidden;

                            -moz-transition: 0.5s;
                            -ms-transition: 0.5s;
                            -o-transition: 0.5s;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;

                            li{
                                span{
                                    font-size: 9px;
                                    font-weight: 500;
                                    color: @gray-sub-light;
                                }

                                &.active{
                                    span{
                                        font-weight: 600;
                                        color: black;
                                    }
                                }
                            }

                            &.active{
                                margin-top: 2px;
                                padding-bottom: 5px;
                                height: auto;
                                max-height: 260px;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }

        .right-panel{
            width: 80% !important;
            height: 100%;

            .periods{
                display: inline-block;

                ul{
                    li{
                        padding-right: 15px;
                    }
                }
            }

            .views{
                float: right;
                text-align: right;
                display: inline-block;
                padding-right: 20px;

                ul{
                    li{
                        padding-right: 15px;
                    }
                }
            }

            .indicator-container, .pie-chart, .timeline{
                height: 0px;
                opacity: 0;

                &.active{
                    height: 100%;
                    opacity: 1;
                    transition: opacity 0.4s;
                }
                .chart-wrapper {
                  height: inherit;
                  opacity: inherit;
                }
            }

            .indicator-container.active{
                padding-top: 130px;
                text-align: center;
                height: 300px;

                .text{
                    margin-left: 5px;
                }
            }

            .pie-chart{
                > div > div {
                    margin: auto;
                    left: -20px;
                }
            }

            .no-data{
                padding-top: 130px;
                text-align: center;
            }
        }
    }
}

.net-flow-table {
    .custom-data-table-container{
        margin-top: 20px;
        position: relative;

        thead th{
            padding: 7px !important;
        }

        tbody tr {
            td{
                padding: 5px !important;

                .legend{
                    position: relative;
                    top: 3px;
                    display: inline-block;
                    border: 1px solid @gray-light;
                    width: 14px !important;
                    height: 14px !important;
                    margin-left: 5px;
                    margin-right: 5px;

                    &:before{
                        position: relative;
                        top: -3px;
                        left: 1px;
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: gray;
                    }
                }
            }

            &:nth-child(1) .legend:before{
                background: @ninja-blue-saturated !important;
            }

            &:nth-child(2) .legend:before{
                background: @light-orange !important;
            }

            &:nth-child(3) .legend:before{
                background: @ninja-green !important;
            }

            &:nth-child(4) .legend:before{
                background: @ninja-red !important;
            }

            &:nth-child(5) .legend:before{
                background: @purple !important;
            }

            &:nth-child(6) .legend:before{
                background: @brown !important;
            }

            &:nth-child(7) .legend:before{
                background: @orchid !important;
            }

            &:nth-child(8) .legend:before{
                background: @casal !important;
            }

            &:nth-child(9) .legend:before{
                background: @key-lime-pie !important;
            }

            &:nth-child(10) .legend:before{
                background: @quincy !important;
            }
        }
    }
}

#nms-details{
    height: 100%;
    overflow: hidden;

    .no-data{
        padding-top: 50px;
        text-align: center;
    }

    #nms-details-content{
        height: 100%;
        padding-right: 10px;
        padding-bottom: 50px;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex: 1;
        flex-direction: column;

        h2.section-title{
            color: black;

            &.first-section-title{
                margin-top: 0px;
                margin-bottom: 20px;
            }
        }

        h4{
            color: black;
            font-size: 1.1em;
            font-weight: 400;
            padding-left: 15px;
            margin-bottom: 0px;
        }

        .ping-monitor{
            .custom-data-table{
                padding-top: 30px;
            }
        }

        #port-map{
            padding: 20px 70px 90px 50px;

            .port{
                text-align: center;
                background: @gray-less-lighter;
                padding: 20px 10px;
                max-height: 69px;

                .leg{
                    display: none;
                }

                &.top{
                    border-top: 2px solid @gray-sub-light;
                }

                &.bottom{
                    border-bottom: 2px solid @gray-sub-light;
                }

                &.left{
                    border-left: 2px solid @gray-sub-light;
                }

                &.right{
                    border-right: 2px solid @gray-sub-light;

                }

                &.top.left{
                    border-top-left-radius: 10px;
                }

                &.top.right{
                    border-top-right-radius: 10px;
                }

                &.bottom.left{
                    border-bottom-left-radius: 10px;
                }

                &.bottom.right{
                    border-bottom-right-radius: 10px;
                }

                &.bottom.left, &.bottom.right{
                    .leg{
                        display: block;
                        position: relative;
                        top: 18px;
                        height: 5px;
                        width: 35px;
                        margin: auto;
                        background: @gray;
                    }

                    // Accomodate for the double borders for single rows
                    &.top .leg{
                        top: 16px;
                    }
                }

                .status{
                    width: 28px;
                    height: 28px;
                    line-height: 25px;
                    margin: auto;
                    margin-top: -2px;
                    color: white;
                    border: 1px solid black;
                    box-shadow: 3px 3px 3px @gray-mid-light;
                    background: @gray-light;

                        &:hover{
                            background: @gray-mid-light;
                        }

                    &.available:hover{
                        cursor: pointer;
                        color: black;

                        + .clip{
                            background: @gray-dark;
                        }
                    }

                    &.up{
                        background: @brand-success;

                        &:hover{
                            background: lighten(@brand-success, 10%);
                        }
                    }

                    &.down{
                        background: @brand-danger;

                        &:hover{
                            background: lighten(@brand-danger, 5%);
                        }
                    }

                    &.warning{
                        background: @brand-warning;

                        &:hover{
                            background: lighten(@brand-warning, 10%);
                        }
                    }
                }

                .clip{
                    margin: auto;
                    width: 14px;
                    height: 3px;
                    background: @gray;
                    box-shadow: 3px 3px 3px @gray-mid-light;
                }
            }
        }

        #syslog{
            .custom-data-table-container{
                padding-top: 10px;
            }
        }

        #cisco-config{
            h4{
                margin-left: 20px;
            }

            .file-contents-container{
                margin: 10px 50px 0 35px;

                .file-contents{
                    max-height: 400px;
                    overflow-y: auto;
                    border: 1px solid @table-border-color;
                    padding: 5px;
                }
            }
        }
    }

    .nms-value {
        text-align: right;
        padding-right: 70px;
    }
}

#nms-settings-content {
    width: 100%;

    #left-panel{
        padding-top: 10px;
        padding-left: 5px;

        li{
            padding-bottom: 3px;
        }
    }

    .frame {
        color: @gray-dark;
        padding: 15px 25px 15px 25px;

        hgroup {
            h4{
                color: black;
                padding-top: 10px;
                font-weight: 500;
            }

            small{
                color: @gray;
            }

            span.btn-link {
                padding-right: 25px;
                font-size: 14px;

                &:first-child{
                    padding-right: 10px;
                }
            }
        }
    }
}

#credential-timeout-input {
  height: 38px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid @ninja-light;
  outline: none;
}
