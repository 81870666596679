#customer-settings {

    padding-top: 30px;

    .horizontal-tiles {
        height: calc(100% - 75px);
        overflow-y: auto;
    }
}

.locations-dropdown {
  max-height: 300px;
  overflow-y: scroll;

  div {
    min-height: 30px;
  }
}


