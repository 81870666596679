@import '~bootstrap/dist/css/bootstrap.css';
@import '~datatables-bootstrap/css/dataTables.bootstrap.min.css';
@import '~intl-tel-input/build/css/intlTelInput.css';
@import "~react-datetime/css/react-datetime.css";
@import "~@fontsource/fira-mono/index.css";
@import "~inter-ui/inter.css";
@import 'animate.css';
@import "variables.less";
@import "layout.less";
@import "base.less";
@import "indicator.less";
@import "bootstrap-timepicker.less";
@import "navigation.less";
@import "activities.less";
@import "bootstrap-overrides.less";
@import "dropdown-menu.less";
@import "no-auth.less";
@import "custom.less";
@import "spacing.less";
@import "horizontal-tile.less";
@import "data-tables.less";
@import "device-search.less";
@import "filters.less";
@import "dashboards/getting-started.less";
@import "dashboards/dashboard.less";
@import "dashboards/customer-settings.less";
@import "dashboards/deviceDashboard/rdpConnect.less";
@import "dashboards/deviceDashboard/device.less";
@import "dashboards/deviceDashboard/command-line.less";
@import "dashboards/deviceDashboard/splashtop.less";
@import "dashboards/deviceDashboard/cloudberry.less";
@import "dashboards/deviceDashboard/vm-dashboard.less";
@import "dashboards/tabs.less";
@import "dashboards/statistics.less";
@import "dashboards/left-panel.less";
@import "dashboards/flot.less";
@import "dashboards/cloud-monitor-dashboard.less";
@import "dashboards/nms-dashboard.less";
@import "configuration/branding.less";
@import "configuration/configurations.less";
@import "configuration/integrations.less";
@import "configuration/psa.less";
@import "modals/ninja-editor.less";
@import "modals/policy-editor.less";
@import "modals/customer-editor.less";
@import "modals/user-editor.less";
@import "modals/shadow-protect-wizard.less";
@import "modals/image-manager-wizard.less";
@import "modals/monitor-wizard.less";
@import "modals/inmodal.less";
@import "modals/u2f-token-editor.less";
@import "super-admin.less";
@import "react-virtualized.less";
@import "date-range-picker.less";
@import "image-loader.less";
@import "scripting.less";
@import "scheduledTasks.less";
@import "file-selector.less";
@import "creatable-select.less";
@import "time-zone-picker.less";
@import "file-folder-browser.less";
@import "file-folder-browser-tree.less";
@import "remote-tools.less";
@import "slider.less";
@import "react-datetime.less";
@import "backup-plans.less";
@import "file-folder-browser-tree.less";
@import "restore-manager.less";

// Plugins
@import "plugins/iCheck/custom.less";
@import "plugins/jquery-slider/ion.rangeSlider.skinFlat.less";
@import "plugins/spinner.less";
@import "plugins/chosen/chosen.less";
@import "plugins/switchery.less";
@import "plugins/notifications.less";
@import "plugins/dataTables.responsive.less";

