.image-loader-container{
  margin-left: 20px;
  text-align: center;

  .dimension-spec-div {
    margin-bottom: 5px;
  }

  .img-preview {
    text-align: center;
    overflow: visible;
    margin: 0 !important;

    .preview-text{
      margin-top: 10px;
    }
  }

  .img-upload {
    margin-top: 10px;

    .red-link {
      color: red;
    }
  }
}

.image-loader-container-warning {
  margin-left: 20px;
  margin-top: 24px
}

