.no-margin {
    margin: 0px !important;
}

.no-margin-x {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.no-margin-b {
    margin-bottom: 0px !important;
}

.p-l-none {
    padding-left: 0px !important;
}

.p-r-none {
    padding-right: 0px !important;
}

.p-r {
  padding-right: 15px;
}

.p-r-sm {
  padding-right: 10px;
}

.p-b {
    padding-bottom: 15px;
}

.p-b-none {
    padding-bottom: 0px !important;
}

.p-l-xs {
  padding-left: 5px;
}

.padding-10 {
    padding: 10px;
}

.p-w-xxs {
    padding: 0 5px;
}

.p-t-none {
    padding-top: 0px !important;
}

.p-t-xxs {
  padding-top: 3px;
}

.p-t-sm {
    padding-top: 5px;
}

.p-t-md {
    padding-top: 7px;
}

.p-t-lg {
    padding-top: 30px;
}

.p-b-sm {
    padding-bottom: 5px;
}

.p-b-md {
    padding-bottom: 7px;
}

.p-l {
  padding-left: 15px !important;
}

.p-l-sm {
  padding-left: 5px;
}


.p-l-lg {
    padding-left: 30px;
}

.p-r-lg {
    padding-right: 30px;
}

.p-r-sm {
    padding-right: 10px !important;
}

.float-right {
    float: right;
}

.clear-left {
    clear: left;
}
