.modal.inmodal
{
    @media screen and (min-width: 950px) {
        .ninja-editor.in-app-inmodal {
            margin-left: 110px;
        }
    }

    .modal-header
    {
        padding: 15px;
        border-bottom: 1px solid @table-border-color;

        h4.modal-title
        {
            display: inline;
            font-weight: 500;
            font-size: 1.2em;
            word-break: break-word;
        }

        button:focus
        {
            outline: 0;
        }
    }

    .modal-body
    {
        text-align: center;
        max-height: 450px;
        overflow-y: auto;

        .form-horizontal{
            .table-headers{
                color: black;
                text-align: center;
            }
        }

        &.tall-modal {
          max-height: 700px;
        }
    }

    // This causes a bleed of the content on the y-axis. Use with caution!
    // Used in add_devices.kit and action.kit where the last item is a chosen select which needs to bleed
    // and the modal-body height won't exceed 400px which prevents static content from bleeding over.
    .overflow-visible
    {
        overflow: visible;
    }

    .overflow-hidden
    {
        overflow: hidden;
    }

    .overflow-y-scroll
    {
        overflow-y: scroll;
    }

    .overflow-visible-important
    {
        overflow: visible !important;
    }

    .chosen-choices {
        max-height: 200px;
        overflow-y: auto;
    }

    .modal-footer
    {
        border-top: 1px solid @table-border-color;
    }

    &#inmodal-modal {
      .control-label {
        text-align: left;
      }
    }

    &#inmodal-modal-lg {
      .control-label{
        text-align: left;
      }

      max-height: 800px;

      .modal-dialog{
        width: 700px;
      }

      .modal-body {
        max-height: 775px;
      }
    }

    &#sub-inmodal-modal-lg {
      .control-label{
        text-align: left;
      }

      .modal-dialog{
        width: 700px;
      }

      .modal-body {
        max-height: 775px;
      }
    }      
}

.modal-sm
{
    margin-top: 40px;
    width: 500px;
}

.modal-md .modal-body
{
    max-height: 1000px !important;
}

.wide-modal {
  width: 900px;
}

.widest-modal {
  width: 1000px;
}

.scroll-modal .modal {
  overflow-y: auto;
}
