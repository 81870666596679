#left-panel {

    height: 100%;

    nav {

        height: 100%;

        ul{
            padding: 0;
            margin: 0;
            list-style: none;
        }
    }

    nav.device-details {
        padding: 0;

        ul{
            padding-top: 10px;
            height: 100%;
            overflow-y: auto;
            li {
                a {
                    font-weight: normal;
                    color: @ninja-medium;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                &.active a, a:active, a:hover {
                    color: @gray;
                    text-shadow: 1px 1px 1px @ninja-medium;
                }

                &:not(.active) a:hover {
                    color: @gray !important;
                }
            }

        }

        .nav-stacked.sub-nav{
            padding: 0;

            a{
                font-size: 0.9em;
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }

    nav.devices {

        h6 {
            padding-left: 5px;
            padding-bottom: 3px;
        }

        li {

            a {
                padding: 0;
                margin: 0;
                padding-left: 15px;
            }

                &.active a, a:active, a:hover {
                    background: transparent none !important;
                    //color: @text-color !important;
                    font-weight: 600;

                    &:before {
                        //color: @text-color;
                    }
                }
        }
    }
}
