#getting-started{
    padding: 0;
    padding-top: 10px;
    margin: 0 -15px;

    h2 {
        font-weight: 500;
    }

    .hide-getting-started {
        margin-top: 10px;
    }

    .action-list {
        padding-left: 0;
        list-style: none;

        .action {
            padding-top: 20px;

            .buttons{
                display: none;
                position: absolute;
                right: 80px;
                top: 30px;
                font-size: 24px;
                span{
                    margin-left:10px;
                }
            }

            .btn-link {
                font-size: 18px;
            }

            .btn-link .disabled{
                color: gray;
                cursor: not-allowed;
            }

            hgroup {

                h4{
                    margin: 0;
                }

                .vertical-dropdown-menu{
                    left: 40px;
                    top: 195px;

                    .dropdown-submenu{
                        .dropdown-menu{
                            overflow-y: scroll;
                            max-height: 250px;
                            a{
                                width: 270px;
                            }
                        }
                    }
                }
            }
        }
    }

    .right-panel > .row {
        margin-left: 0;
        margin-right: 0;
    }

    #community-links {
        padding-top: 20px;
        .community-link {
            padding: 0;
            &:not(:last-of-type) {
                padding-right: 10px;
            }
            > a {
                border: 1px solid @ninja-light;
                border-radius: 1px;
                padding: 15px 0;
                display: block;
                > span {
                    display: block;
                    font-size: 12px;
                    &.center-icons {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                    }
                    &:not(.center-icons) {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .links-container {
        padding-top: 20px;
        .link-container {
            padding: 0;
            &:first-of-type {
                padding-right: 10px;
            }
            /*
                TODO: standardize this table's css. It is being used
                for general-tab API as well.
            */
            .link-title {
                border: 1px solid @table-border-color;
                border-bottom: none;
                padding: 10px;
                height: 54px;
                .sub-header {
                    font-weight: 400;
                    display: inline-block;
                    color: @black;
                    margin-top: 8px;
                    margin-bottom: 0;
                }
                span > a {
                    margin: 0;
                }
            }
            ul.horizontal-tiles {
                overflow-y: auto;
                border: 1px solid @table-border-color;
                &:not(.border-bottom) {
                  border-bottom: none;
                }
                width: 100%;
                .horizontal-tile {
                    display: block;
                    a {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}
