#vm-dashboard-container {
  .horizontal-tiles {
    .icon {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .horizontal-tile {
    .vertical-dropdown-menu {
      min-width: 150px;
      left: -100px;
      li {
        padding: 10px;
        width: 150px;
      }
    }
    .open { 
      .vertical-dropdown-menu {
        animation: none;
        top: 34px;
      }
    }
  }
  .vm-results-label {
    color: @ninja-dark;
    font-weight: 400;
  }
  #left-panel nav.device-details ul li.active span,
  #left-panel nav.device-details ul li span:active,
  #left-panel nav.device-details ul li span:hover {
    color: @ninja-dark;
    text-shadow: 1px 1px 1px @ninja-medium;
    cursor: pointer;
  }
  #left-panel nav.device-details ul li span:hover {
    background-color: @ninja-light;
  }
  #left-panel nav.device-details ul li span {
    font-weight: 400;
    color: @ninja-medium;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    display: inline-block;
    width: 100%;
  }

  .mini-flot {
    padding: 0; 
    position: relative;
    overflow: hidden
  }

  #device-details-content {
    h2.section-title {
      margin-top: 20px;
    }
  }

  .sorting-table {    
    
    .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
      margin-left: 0;
    }
    .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn {
      margin-right: 0;
    }
  
    .ReactVirtualized__List,
    .ReactVirtualized__Table {
      .ReactVirtualized__Table__headerRow {
        .ReactVirtualized__Table__headerColumn {
          margin-left: 5px;
          margin-right: 10px;
        }
      }
      .horizontal-tile {
        border-bottom: 1px solid @border-color;
      }
    }
    
    .table-row:not(.ReactVirtualized__Table__headerRow) {

      .vm-dashboard-table-cell {
        margin-left: 5px;
        margin-right: 10px;
        user-select: none;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
  
      &:not(:last-child) {
        border-bottom: 1px solid @border-color;
      }
  
      &:hover {
        cursor: pointer;
        background-color: @ninja-white;
      }
    }
  }

}
