.invalid-multi-select {
  .multi-select__control {
    border-color: @ninja-red;

    &:hover {
      border-color: @ninja-red;
    }
  }
}

.invalid-single-select > div {
  border-color: @ninja-red;

  &:hover {
    border-color: @ninja-red;
  }
}

.multi-select-container {
  .multi-select__placeholder {
    position: absolute;
    top: 17px;
  }

  .multi-select__control {
    align-items: flex-start;
    overflow-y: hidden;
    height: 100px;

    &.multi-select__control--is-focused {
      box-shadow: none; 
      border: 1px solid @ninja-blue-saturated;
    }
  }

  .multi-select__value-container {
    display: block;
    height: 100%;
    overflow-y: scroll;
    padding-right: 50px;
    overflow-x: hidden;

    > div {
      height: 20px;
      float: left;
    }
  }

  .multi-select__indicators {
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    right: 10px;
  }
}
