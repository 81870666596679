.tabs-header-panel{
	&.has-heading{
		margin-top: -30px;
	}
	line-height: 0px;
	padding: 0px;
	ul{
		padding: 10px 15px 20px 15px;
		display: table;
		margin: 0 auto;
		li{
			display: inline;
			padding: 0 10px;
			font-size: 1.1em;
			margin-right: 30px;
			a{
				color: @gray-light;
				&:hover{
					text-decoration: none;
					color: @gray;
				}
				&:focus{
					text-decoration: none;
				}
			}
			&.active a{
				color: black;
				font-weight: 600;
			}
		}
	}
}
