.sub-nav-tabs {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        font-size: 1.1em;
        &:not(:last-of-type) {
            padding-bottom: 10px;
        }
        a {
            color: @gray-light;
            &:hover {
                text-decoration: none;
                color: @gray;
            }
            &:focus {
                text-decoration: none;
            }
        }
        &.active a {
            color: black;
            font-weight: 600;
        }
    }
}

.padding-top-none-important{
    padding-top: 0 !important
}
