.panel.panel-borderless {
  border: 0;
  margin-bottom: 0 !important;
}

.panel-bordered {
  .ReactVirtualized__Table__Grid {
    border-radius: 0 0 4px 4px;
    border: 1px solid @ninja-medium;
    border-top: 0;
  }
}

.ReactVirtualized__List,
.ReactVirtualized__Table {
  .horizontal-tile,
  .ReactVirtualized__Grid,
  .ReactVirtualized__Table__headerColumn {
    outline: none;
  }

  .horizontal-tile {
    border-bottom: 0;
  }

  .overflow .ReactVirtualized__Table__headerTruncatedText {
    overflow: visible;
  }

  .ReactVirtualized__Table__headerTruncatedText {
    user-select: none;
    font-weight: 600;
    font-size: 12px;
    white-space: no-wrap;
    color: @ninja-black;
  }

  .ReactVirtualized__Table__headerRow {
    text-transform: inherit;
    padding: 10px 0;
    .ReactVirtualized__Table__headerColumn {
      display: flex;
      align-items: center;
      margin: 0;
      &.center {
        justify-content: center;
      }
      &.right {
        justify-content: flex-end;
      }
    }
  }

  .ReactVirtualized__Table__Grid {
    .action-links {
      text-align: right;
      width: 100%;
    }

    .center {
      text-align: center;
    }

    hgroup {
      flex: 1;
      padding-right: 15px;
      min-width: 212px;
    }

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-5 {
      flex: 1 1 1px;
    }
    .col-xs-1 {
      &:not(.no-max-width) {
        max-width: 106px;
      }
    }
    .col-xs-2 {
      &:not(.no-max-width) {
        max-width: 212px;
      }
    }
    .col-xs-3 {
      &:not(.no-max-width) {
        max-width: 318px;
      }
    }
    .col-xs-5 {
      &:not(.no-min-width) {
        min-width: 350px;
      }
      &:not(.no-max-width) {
        max-width: 515px;
      }
    }
  }
}

.dropdown-actions .ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Table__row {
  outline: none;

  .action-links {
    display: none;
  }

  &:hover {
    .action-links {
      display: block;

      button, span {
        margin-left: 10px;
      }
    }
  }
}

.virtualized-table {
  &.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 0;
    padding-left: 5px;
  }

  .ReactVirtualized__Table__headerRow {
    border: none !important;

    .ReactVirtualized__Table__headerColumn {
      margin: 0 10px 0 0;
      padding-left: 5px;
    }
  }

  .action-links {
    flex: 1;
  }

  .table-cell.table-cell-checkbox,
  .table-header.table-cell-checkbox {
    display: flex;
    justify-content: center;
    margin: 0 12px !important;
    padding-left: 0 !important;
    flex: 0 1 32px !important;
    height: 32px !important;
    border-radius: 4px;
  }

  .table-cell {
    &:first-child {
      &:not(.table-cell-checkbox) {
        &:not(.no-margin-left) {
          margin-left: 12px !important;
        }
      }
    }
  }

  .table-header {
    &:first-child {
      &:not(.table-cell-checkbox) {
        &:not(.no-margin-left) {
          margin-left: 12px !important;
        }
      }
    }
  }

  .table-row:not(.ReactVirtualized__Table__headerRow) {
    display: flex;
    align-items: center;

    .ReactVirtualized__Table__rowColumn, .table-cell {
      margin: 0 10px 0 0;
      padding-left: 5px;
      display: flex;
      align-items: center;
      height: inherit;
    }

    &.active-row {
      .table-cell.table-cell-checkbox {
        background-color: @ninja-light;
      }
    }

    &.checked {
      background-color: @ninja-light-blue !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid @border-color;
    }

    &:not(.no-hover):hover {
      cursor: pointer;
      background-color: @ninja-white;
    }

    &:active, &:focus {
      outline: none !important;
    }
  }
}

.table-row-with-actions:not(.ReactVirtualized__Table__headerRow) {
  .row-actions {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: @ninja-white;

    .row-actions {
      display: block;
    }
  }
}

.table-row-with-bottom-border:not(.ReactVirtualized__Table__headerRow){
  &:not(:last-child) {
    border-bottom: 1px solid @ninja-light;
  }
}
