.command-line-output {
  height: 22px;
  font-size: 15px;
}

textarea.command-line-input {
  border: none;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  outline: none;
  resize: none;
  background: transparent;
  height: 22px;
  width: 500px;
  padding: 4px 0px;
  line-height: normal;
  vertical-align: middle;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
}

textarea::-webkit-input-placeholder {
  color: blue;
  text-shadow: none;
  -webkit-text-fill-color: initial;
}

.line {
  vertical-align: bottom;
  white-space: pre-wrap;
}

#terminal {
  padding-bottom: 40px;
  color: white;
  font-family: Lucida Console,Lucida Sans Typewriter,monaco,Bitstream Vera Sans Mono,monospace;
  line-height: normal;
  height: 500px;
  width: 1000px;
  opacity: 1;
  text-align: left;
  overflow: auto;
}

div#terminal.powershell {
  background: navy;
}

div#terminal.cmd {
  background: black;
}

.command-line-modal {
  .modal-action {
    margin-right: 10px;
  }
}

#command-line-modal {
  width: 1000px;
}

.command-line-modal .modal-content {
  background: transparent;
  outline: none;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
.command-line-modal .modal-header {
  background-color: @ninja-light;
  width: 1000px;
  text-align: left;
  color: black;
  border: solid black 1px;
}
.command-line-modal .modal-body {
  padding: 5px;
}
.command-line-modal .modal-footer {
  padding: 40px;
  background: white;
}

li#command-line-menu.dropdown-submenu > a:after {
  display: none;
}

#terminal {
  font-size: 15px;
  .line:last-child {
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: auto;
    .prompt-output {
      white-space: pre-wrap;
    }
    .command-line-input {
      flex: 1;
      outline: none;
      width: auto;
      background-color: black;
      border: none;
    }
  }
}

.terminal-button {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  float: left;
  font-size: 27px;
  font-weight: 700;
  line-height: 1;
  color: rgb(0, 0, 0);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  opacity: 0.2;
  margin-right: 8px;
}

.maccommand-line-modal {
  position: absolute;
  .modal-body {
    padding: 5px;
  }
  .modal-footer {
    padding: 40px;
    background: white;
  }
  .modal-content {
    background: transparent;
    outline: none;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .modal-header {
    background: #a1bbd7;
    width: inherit;
    text-align: left;
    color: black;
    border: solid black 1px;
  }

  .modal-action {
    margin-right: 10px;
  }
}

#maccommand-line-modal {
  display: block !important;
  position: fixed !important;
  z-index: 9999;
}

.disable-background{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
}

