#shadow-protect-wizard-modal {

    .content-container{
        height: calc(~"100% - 210px") !important;
    }

    .chosen-container-multi{
        .chosen-choices{
            .search-field {
                input {
                    min-width: 110px;
                    max-width: 130px;
                }
            }
        }
    }

    header{

		padding-top: 6px;

		&:not(.has-steps){
			margin-bottom: 50px;
		}

		.menu-buttons{
			margin-top: 15px;
		}

		.editor-name{
	    	padding-left: 10px;
	    	font-size: 1.4em;
	    }

		.steps{
			margin-top: 30px;

			ul{
                padding-left: 0;
				text-align: center;
				list-style-type: none;

				li{
					display: inline-block;

					.progress{
						height: 0.8em;
						margin: 0 5px;
						width: 125px;

						.progress-bar{
							width: 100%;
						}
					}

					.step-label{
						display: inline;
						line-height: 2em;
						font-size: 1.1em;

						&.active{
							color: black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
