.file-folder-browser-tree {
  overflow-y: scroll;
  background-color: #fff;
  padding: 5px 15px !important;

  .folder-icon {
    color: @ninja-yellow;
  }

  .file-icon {
    color: @ninja-medium;
  }

  .node-disabled {
    .rc-tree-node-content-wrapper {
      color: @ninja-light !important;
    }

    .rc-tree-iconEle {
      svg {
        color: @ninja-light !important;
      }
    }
  }

  .rc-tree-treenode {
    position: relative !important;
    height: 25px !important;
    display: flex !important;
    align-items: center !important;

    &.rc-tree-treenode-selected {
      .rc-tree-node-content-wrapper {
        background-color: rgba(255, 189, 3, 0.3);

        .rc-tree-title {
          color: #000;
        }
      }
    }

    .rc-tree-indent-unit {
      padding-left: 20px;
    }

    .rc-tree-switcher {
      width: 10px;
      margin-right: 5px;
      display: inline-block;
      background-image: none !important;
    }

    .rc-tree-switcher-noop {
      width: 0px !important;
      margin-right: 0px !important;
    }

    .rc-tree-node-content-wrapper {
      border: none;
      height: 20px !important;
      padding: 0 5px !important;
      display: inline-flex !important;
      align-items: center !important;

      .rc-tree-icon__customize {
        line-height: 0px;
      }

      .rc-tree-title {
        padding-left: 10px;
      }
    }

    &.checked-disabled {
      .rc-tree-checkbox {
        background-position: -80px 0px !important;
      }
    }

    &.checked-indeterminate {
      >.rc-tree-checkbox {
        background-position: -40px 0px !important;
      }
    }

    .rc-tree-checkbox {
      width: 20px !important;
      height: 20px !important;
      display: inline-block !important;
      background: data-uri('./plugins/iCheck/iblue.png') no-repeat !important;
      margin-right: 5px !important;

      &:hover:not(.rc-tree-checkbox-checked):not(.rc-tree-checkbox-disabled) {
        background-position: -20px 0px !important;
      }

      &.rc-tree-checkbox-indeterminate {
        background-position: -40px 0px !important;
      }

      &.rc-tree-checkbox-checked {
        background-position: -60px -0px !important;
      }
    }
  }
}
