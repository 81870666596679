@ninja-white: #FAFBFD;
@ninja-light: #E1E2E4;
@ninja-medium: #949597;
@ninja-dark: #616264;
@ninja-black: #151618;
@ninja-gray: #f9f9f9;

@ninja-red: #D53948;
@ninja-yellow: #FAC905;
@ninja-light-yellow: #FEF0CF;
@ninja-green: #26A644;

@ninja-blue: #2F4050;
@ninja-blue-saturated: #337ab7;
@ninja-blue-dark: #23527c;
@ninja-light-blue: #ebf4fd;

@pink: #ac5287;
@purple: #6E587A;
@blue: #57889C;

@light-grey: #E1E2E4;
@light-orange: #ff7f50;
@brown: #8c564b;
@orchid: #e377c2;

@casal: #236467;
@key-lime-pie: #bcbd22;
@quincy: #5a392d;
@java: #17becf;

@tussock: #c8993a;
@purple-heart: #7142c9;
@mantis: #69ce56;
@fuchsia-pink: #c953c0;
@wattle: #c8d748;
@jacarta: #4f2c6e;
@downy: #6dd3ae;
@punch: #d94b31;
@blue-marguerite: #6c7aca;
@chalet-green: #537237;
@cabaret: #cc3f6f;
@pine-glade: #c9d39e;
@buccaneer: #592631;
@glacier: #6faabe;
@burnt-umber: #8d4326;
@prelude: #ccafd7;
@limed-spruce: #364146;
@copperfield: #de8e6f;
@turkish-rose: #b86f91;

@react-virtualized-blue: #2684FF;
@react-virtualized-light-blue: #DEEBFF;
@react-virtualized-light-grey: #cccccc;
@react-virtualized-grey: #e6e6e6;
@react-virtualized-red: #de350b;
@react-virtualized-pink: #ffbdad;

@react-select-light: #F2F2F2;
@react-select-dark-grey: #999999;

@script-editor-form-grey: #474747;

// Depreciated colors:

@navy: @ninja-blue;//#57889C;       // Primary color
@lazur: @ninja-blue;//#23c6c8;      // Info color
@yellow: @ninja-yellow;//#f8ac59;     // Warrning color
@red: @ninja-red;//#ED5565;        // Danger color
@green: @ninja-green;
@text-color: @ninja-dark;//#676a6c;   // Body text
// @gray: @ninja-white;//#f3f3f4;         // Background wrapper color
@label-badget-color: @ninja-dark;//#5E5E5E;
@spin-color: @ninja-dark;//#555;

@border-color: @ninja-light;//#e7eaec;
@brand-primary: @ninja-blue;//#3276B1;
@brand-success: @ninja-blue;//#739E73;
@brand-warning: @ninja-yellow;//lighten(#C79121, 5%);
@brand-danger: @ninja-red;//#ba0000;
@brand-info: @ninja-blue;//#57889C;

@state-warning-text: @ninja-black;//#6B6D31;
@state-warning-bg: @ninja-yellow;//#ECDFB0;
@state-warning-border: @ninja-yellow;//darken(spin(@state-warning-bg, -10), 7%);

@state-danger-text: @ninja-white;//#DA3838;
@state-danger-bg: @ninja-red;//#C46A69;
@state-danger-border: @ninja-red;//#D24A49;

@state-success-text: @ninja-white;//#296829;
@state-success-bg: @ninja-green;//#CEE0CF;
@state-success-border: @ninja-green;//#6B9A67;

@state-info-text: @ninja-white;//#346597;
@state-info-bg: @ninja-blue;//#D6DDE7;
@state-info-border: @ninja-blue;//darken(spin(@state-info-bg, -10), 18%);

// Grays
// -------------------------
@white: #ffffff;
@black: @ninja-black;//#000;
@light-gray: @ninja-light;//#D1DADE;
@gray-darkest: @ninja-black;//lighten(@black, 5.5%);  // #111'ish?
@gray-darker: @ninja-black;//lighten(@black, 13.5%); // #222
@gray-dark: @ninja-dark;//lighten(@black, 20%);   // #333
@gray-darkish: @ninja-dark;//lighten(@black, 24%);
@gray: @ninja-dark;//lighten(@black, 33.5%); // #555
@gray-sub-light: @ninja-dark;//lighten(@black, 50%); // #555
@gray-light: @ninja-medium;//lighten(@black, 60%);   // #999
@gray-mid-light: @ninja-medium;//lighten(@black, 75%);   // #CCC'ish?
@gray-less-lighter: @ninja-light;//lighten(@black, 84%);
@gray-lighter: @ninja-light;//lighten(@black, 93.5%); // #eee
@gray-lightest: @ninja-white;//lighten(@black, 95.5%); // #fafafa'ish?

@blueStandard: @ninja-blue;//#3276B1;
@blueSky: @ninja-blue;//#0091D9;
@yellow: @ninja-yellow;//#b09b5b;
@redLight: @ninja-red;//#a65858;
@off-white: @ninja-white;//#fafafa;
@table-border-color: @ninja-light;//#ddd;
@gray-border-color: @ninja-gray;//#f9f9f9;

/*
 * TEXT COLOR
 */
.txt-color-blue {
  color: @blue !important;
}
.txt-color-yellow {
  color: @yellow !important;
}
.txt-color-redLight {
  color: @red !important;
}

/*
 * TEXT CUSTOM COLORS
 */
.txt-info{
  color: @ninja-blue;//lighten(@brand-primary, 10%);
}
.txt-warning{
  color: @brand-warning;
}

// theme colors
@theme-light-gray: #f6f5f7;
@theme-gray: #D1D0DA;
@theme-input-text: #211f33;
@theme-text: #484469;

// Border colors
@color-border-weak: #CAD0D6;
@color-border-weakest: #DDE2E7;

// Text colors
@color-text-weak: #363B3E;
@color-text-weakest: #5B666C;
@color-text-strong: #151617;


// Alert colors
@color-alert-neutral-weak: #DDE2E7;

