#backups-organization-editor {
  padding: 0 15px;

  h3 {
    font-size: 20px;
    font-weight: normal;
  }
}

#backup-deployment {
  min-height: 270px;
  padding: 0 15px;
}

.file-browser-spinner {
  color: #ADE1EB
}

.file-browser-icon {
  padding-left: 5px
}

.cloudberry-error {
  padding: 5px;
  color: darkred;
}

.restore-error {
  padding-top: 10px;
  padding-left: 20px;
  color: darkred;
}

#cloudberry-heading {
  padding-left: 20px;
}

h3.backup-setting {
  margin-top: 30px;
}

.network-location {
  line-height: 25px;
  border-bottom: solid @ninja-light 1px;
  
  .make-default-btn {
    display: none;
  }

  &:hover {
    .make-default-btn {
      display: block;
    }
  }
}

.cloudberry-link {
  font-size: 14px;
  float: right;
}

#cloudberry-file-folder-restore .modal-content {
  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

#cloudberry-bare-metal-restore .modal-content {
  width: 800px;

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  h3 {
    margin: 20px 0px 10px 20px;
  }

  input {
    margin-left: 20px;
    width: 350px;
  }

  #encryption-passphrase {
    margin-top: 20px;
  }

  .passphrase-showhide {
    font-size: 12px;
  }

  #destination-image-file-browser-container {
    margin: 20px 15px 0px 15px;
    height: 210px;
    width: 90%;
    overflow: scroll;
    background: white;
    border: solid #ccc 1px;
  }

}

#cloudberry-virtual-disk-restore .modal-content {
  width: 800px;
  height: 710px;
}

#cloudberry-virtual-disk-restore {
  h3 {
    margin-left: 20px;
  }

  h4 {
    font-weight: normal;
    margin-top: 20px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }


  select {
    width: 90%;
  }

  .right-content {
    padding-left: 20px;

    input {
      width: 90%;
    }
  }

  .modal-body {
    max-height: 600px;
  }
}

#cloudberry-virtual-disk-restore #source-file-browser-container {
  margin: 15px 15px 0px 15px;
  height: 200px;
  width: 90%;
  overflow: scroll;
  background: white;
  border: solid @ninja-light 1px;
}

#cloudberry-physical-disk-restore .modal-content {
  width: 800px;
}

#cloudberry-physical-disk-restore {
  h3 {
    margin-left: 20px;
  }

  h4 {
    font-weight: normal;
    margin-top: 20px;
  }

  select {
    width: 90%;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .right-content {
    padding-top: 20px;
    padding-left: 20px;

    input {
      width: 90%;
    }
  }

  .modal-body {
    max-height: 600px;
  }

  #destination-file-browser-container {
    margin-top: 15px;
    height: 365px;
  }
}

#cloudberry-physical-disk-restore #source-file-browser-container {
  margin: 15px 15px 0px 15px;
  height: 200px;
  width: 90%;
  overflow: scroll;
  background: white;
  border: solid @ninja-light 1px;
}

#cloudberry-file-folder-restore {
  width: 1000px;
}

#cloudberry-file-folder-restore h3 {
  margin-left: 20px;
}

#cloudberry-file-folder-restore .modal-body {
  max-height: 600px;
}

#source-tree-loading {
  padding: 10px;
}

#source-tree-message {
  padding: 10px;
}

.cloudberry-content {
  margin: 10px;
}

.read-only-timezone {
  line-height: 32px;
}

#backup-plan-file-browser-container {
  overflow: auto;
  background: white;
  border: solid @ninja-light 1px;
  height: 200px;
}

#source-file-browser-container {
  margin: 45px 15px 0px 15px;
  height: 150px;
  width: 90%;
  overflow: scroll;
  background: white;
  border: solid @ninja-light 1px;
}

#destination-file-browser-container {
  margin: 15px 15px 0px 15px;
  height: 200px;
  width: 90%;
  overflow: scroll;
  background: white;
  border: solid @ninja-light 1px;
}

#destination-image-file-browser-container {
  margin: 15px 15px 0px 15px;
  height: 200px;
  width: 90%;
  overflow: scroll;
  background: white;
  border: solid #ccc 1px;
}

#fancytree {
  overflow: auto;
  background: white;

  ul.fancytree-container {
    border: 0;
  }
}

#fancytree-loading {
  background: white;
  padding: 20px;
}
#path-list {
  width: 100%;
}

#restore-link {
  float: right;
}

#device-backup-plan-list {
  margin: 15px;
}

.cloudberry-option {
  margin: 10px 0px 10px 20px;
}

#restore-options {
  margin-top: 40px;
}
#restore-timepicker {
  margin-left: 16px;
  margin-top: 10px;
}

#policy-editor-backup-modal .invalid.extension {
  margin-left: 35px;
}

#policy-editor-backup-modal .invalid.source {
  margin: 10px 0px 10px 15px;
  color: darkred;
}

#policy-editor-backup-modal .invalid.retention {
  margin: 10px 0px 10px 15px;
  color: darkred;
}

#integration-notes {
  line-height: 24px;
}

#backupSourceTab {
  text-align: left;
}

.backup-tab.has-error {
  color: @ninja-red;
  font-weight: bold;
}

#add-backup-plan {
  margin-right: 25px;
}

#add-backup-plan .btn-link {
  font-size: 13px;
}

.backup-offset {
  margin-left: 20px;
}

#common-folders {
  margin-left: 10px;
}

.device-backup-plan {
  border: solid @ninja-light 1px;
  border-width: 0px 0px 1px 0px;
  padding-top: 10px;
}

.retention-row {
  margin-left: 35px;
}

.inline-retention {
  margin-top: 10px;
  width: 50px;
  display: inline;
}

.run-now {
  float: right;
}

#prefix {
  margin: 15px 15px 0px 15px;
  width: 90%;
}

#source-type-options {
  margin: 5px 0px 15px 0px;
}

#device-label {
  font-weight: normal;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 0px;
}

#backed-up-files-label {
  font-weight: normal;
  margin-left: 20px;
  margin-top: 10px;
}

#network-location {
  display: inline;
  width: 200px;
}

#new-folder {
  width: 90%;
  margin: 5px 0px 5px 10px;
}

#destination-type-options {
  padding: 3px;
}

#device-mode {
  cursor: pointer;
}

#list-mode {
  cursor: pointer;
  float: right;
  font-size: 14px;
}

#path-list {
  height: 200px;
  overflow: auto;
  text-align: left;
  background: white;
  margin: 0;
  padding: 10px;


  li {
    line-height: 28px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-bottom: solid @ninja-light 1px;
  }
  //li:hover {
  //  background: lightblue;
  //}
  a {
    float: right;
  }
  tbody {
    height: 20px;
  }
  tr {
    height: 20px;
  }

  th {
    height: 20px;
  }
}

.highlighted-node .fancytree-title {
  background: lightgreen;
}

.explicit-path {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 480px;
}

#delete-data {
  float: right;
}

#delete-files, #synchronize-repository {
  float: left;
}

#device-storage-list {
  text-align: left;

  .device-storage-item {
    padding: 5px;
  }

  .device-storage-name {
    margin-left: 5px;
  }
}
