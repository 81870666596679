.ninja-editor {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2050;
    border: 0;

    em.info {
      color: @gray-darkish !important;
    }

    .black {
        color: black;
    }

    .no-padding{
        padding: 0;
    }

    .hide{
        display: none !important;
    }

    .option-subheader{
        margin: 10px 0 10px 0;
    }

    .header {
        margin: 10px 0 20px 10px;

        .entity-icon .fa{
            font-size: 2em;
            padding-top: 10px;
            margin-right: 15px;
        }

        .editor-name{
            font-size: 20px;
            word-break: break-word;

            .fa{
                margin-left: 5px;
                font-size: 0.9em;
                color: @gray-dark;
            }
        }

        .editor-name:not(.has-description){
            padding-top: 10px;
        }
    }

    .editor-container {
        height: calc(~"100% - 50px");
        padding-left: 40px;

      .control-label {
        text-align: left;
        font-weight: 600;
        padding-left: 30px;
      }
    }

    .nav-tabs {
        //max-width: 356px;
        text-align: center;

        li {
            &.active a {
                border-bottom-width: 1px;
                border-bottom-style: solid;
                border-bottom-color: white;
            }

            a {
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
                border-radius: 0;
                margin-right: 0;
            }

            padding: 0;
        }
    }

    .content-container {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(~"100% - 135px");
        padding: 20px 20px 0 0;
        border-bottom: 1px solid @ninja-medium;

        .custom-schedule{
            width: 225px;
            padding: 10px 0 5px 20px;
            text-align: center;

            .bootstrap-timepicker input{
                width: 64px;
            }
        }

        .select-name{
            padding-top: 7px;
        }

        .radio-in-one-row {
            margin-right: 5px;
            float: left;
        }

        .min-height{
            min-height: 49px;
        }

        .tab-pane{
            display: none;

            &.active{
                display: block;
            }
        }

        &.form-blue{
            .form-control:focus, .single-line:focus {
              border-color: @blue !important;
            }
        }
    }

    .input-file .button {
        position: absolute;
        top: 5px;
        right: 20px;
        float: none;
        height: 24px;
        margin: 0;
        padding: 0 14px;
        font-size: 13px;
        line-height: 22px;
        overflow: hidden;

        input {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            font-size: 30px;
            cursor: pointer;
            opacity: 0;
        }
    }

    .padding-md{
        padding: 20px;
    }

    .menu-buttons{
        padding: 10px 10px 0px 0px;
    }

    .application-upload{

        height: 34px !important;

        .input-file .btn {
            position: absolute;
            float: none;
            font-size: 13px;
            input {
                position: absolute;
                top: 0;
                left: 0;
                padding: 0;
                cursor: pointer;
                opacity: 0;
                width: 80px;
                height: 20px;
            }
        }

        ins.iCheck-helper{
            width: 22px !important;
        }

        .no-text-input .btn{
            margin-left: 10px;
            top: 3px;

            input{
                width: 72px;
                height: 32px;
            }
        }

        .bar-holder{
            position: relative;
            left: 120px;
            width: 60%;
        }
    }

    .form-group {
        em {
            text-align: left;
            display: block;
            margin-top: 6px;
            padding: 0 1px;
            font-style: normal;
            font-size: 11px;
            line-height: 15px;
            color: @ninja-red;
        }

        em.lg-em{
            font-size: 13px;
        }

        em.text-center{
            text-align: center;
        }

        &.inline-checkboxes {
            .iCheck-helper{
                position: inherit !important;
            }
            .checkbox {
                min-width: 65px;
                padding-left: 0;
                padding-right: 0;

                label>div {
                    margin-right: 5px;
                }
            }
        }

        &.has-error .chosen-container > {
            ul, a {
                border-color: @red;
            }
        }

        label.top-label {
            width: 100%;
            text-align: left;
            padding-bottom: 7px;
        }
    }

    table.table-tiles{
        tr{

            td{
                border: 0;
            }

            td.kill-button{
                vertical-align: middle;

                span.btn{
                    visibility: hidden;
                    padding: 3px 8px;

                    i{
                        font-size: 0.9em;
                    }
                }
            }

            &:hover td.kill-button span.btn{
                visibility: visible;
            }
        }
    }

    &.read-only{
        .pointer{
            cursor: not-allowed !important;
        }

        .content-container > *:not(.not-read-only) {
            label, a, li, select, [class*="checkbox"], [class*="row"], .btn-link, .switchery {
                cursor: not-allowed !important;
                pointer-events: none !important;
            }
        }
    }

    #condition-name {
        margin-top: 0;
    }

    .no-pack-condition {
        > h4 {
            margin-bottom: 5px;
        }
        > small {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 0;
            padding: 0;
            background-color: transparent;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            color: @ninja-medium;
        }
        > span {
            display: block;
            margin-top: 5px;
        }
    }

    &.redux-form-modal {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .modal-dialog {
        display: flex;
        margin: 30px auto 0;
        max-height: calc(~"100% - 98px");
        min-width: 700px;
        .modal-header {
          padding: 15px;
          border-bottom: 1px solid @ninja-light;
          .modal-title {
            font-weight: 500;
            font-size: 1.2em;
          }
        }
        .modal-content {
          display: flex;
          flex-direction: column;
          flex: 1;
          .modal-body {
            overflow-y: auto;
            .form-group {
              display: flex;
              margin: 0 0 15px 0;
              &:last-child {
                margin-bottom: 0;
              }
              em.invalid {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              &:not(.radio-group) label {
                display: flex;
                flex: 1;
                margin: 0;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
      }
    }
}

.ninja-mini-editor{
    .modal-body {
        padding: 0px 30px 30px 20px;
    }

    .modal-title{
        font-weight: 400;
        color: black;
    }

    .modal-content{
        border-radius: 10px;
    }
}

.ninja-editor, .ninja-modal-container{

    .btn-group.weekday-group {
        padding: 0px 0px 0px 0 !important;

        .weekday {
            width: 25px !important;
            height: 25px !important;
            padding-top: 2px !important;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            color: @ninja-medium;

            &.active{
                color: black;
                background-color: @ninja-light;
            }

            &:active{
                color: white;
                background-color: @ninja-light;
            }

            &:hover{
                color: white;
                background-color: @gray-light;
            }

            &.btn > input[type="checkbox"] {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }

            span{
                position: relative;
                right: 5px;
            }
        }
    }

    .timezone-dropdown {

        > div.dropdown {

            &.open {

                button .fa-caret-down {
                    transform: rotate(180deg);
                }
            }

            button {
                background-color: #fff !important;
                width: 100%;
                max-width: 300px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    display: inline-block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 80%;
                    overflow: hidden;
                }

                i {
                    margin-top: 3px;
                    margin-left: 30px;
                    float: right;
                }
            }

            ul {
                max-height: 360px;
                overflow-y: scroll;
                left: 2px;

                .tz-common-name {
                    font-weight: 700;
                }

                .tz-name {
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 70%;
                    float: left;
                }

                .tz-offset {
                    float: right;
                }

                .tz-current-time {
                    float: right;
                }

                li {

                    &.guess {
                        border-bottom: 1px solid @table-border-color;
                    }

                    &.internal {
                        border-bottom: 1px solid @table-border-color;
                    }

                    &.divider {
                        margin: 0;
                        background-color: @table-border-color;
                    }

                    i {
                        left: 0;
                        top: 0;
                        margin-left: 5px;
                    }

                    > a {
                        font-size: 11px;
                        padding: 10px 20px;
                        margin: 0;
                        border-radius: 0;
                        background-color: #fff !important;
                        height: 50px;
                        &:hover {
                            background-color: @ninja-white !important;
                        }
                    }

                    .show-more-dropdown {
                        span {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .tz-more-locations ~ * {
            display: none;

            > a > span {
                &:last-of-type {
                    font-weight: 700;
                }
            }
        }
    }
}

.ninja-modal-container .ninja-header{
    height: 50px;

    .container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      &:before, &:after {
        content: none;
      }
    }

    #ninja-header-logo {
      margin: 5px 0 0 20px;
      height: 24px;
    }

    .progress-bar{
        width: 100%;
    }

    span{
        position: relative;
        top: 2px;
        line-height: 39px;
        margin-left: 18px;
        font-size: 1.7em;
        font-weight: 600;
    }
}

#system-team-viewer-setup, #team-viewer-connect, #device-team-viewer {
    .modal-header-contains-logo {
        position: relative;
        padding-left: 31px;
        > img {
            position: absolute;
            top: 0;
            left: 0;
            height: 22px;
            width: 22px;
        }
    }

    #device-option-modal {
      max-height: 650px;
    }

    .device-option {
      font-size: 16px;
      padding-left: 10px;
    }

    .device-option-caption {
      color: @ninja-medium;
      padding: 0px 0px 10px 37px;
      width: 460px;
    }

    .device-option-managed {
      padding: 0px 0px 10px 37px;
    }

    .device-option-managed select.form-control {
      width: 400px;
    }

}

.input-group-addon {
    border: 0;
    padding-left: 20px;
    background: transparent;
}

/* sidebar */
.editor-sidebar {

  border-right: solid @ninja-medium 1px;
  margin-top: 20px;

  .nav {
    & > li {
      & > a {
        color: @ninja-medium;
        padding: 4px 0px;
        font-size: 13px;
        font-weight: 400;
      }
      &.active {
        border-left: none;
      }
    }

    .nav {
      & > li > a {
        padding-top: 1px;
        padding-bottom: 1px;
        padding-left: 30px;
        font-size: 12px;
      }

      & > .active > a, & > .active:hover > a, & > .active:focus > a {
        font-weight: 500;
      }
    }

    & > .active > a, & > li > a:hover, & > li > a:focus {
      // TODO: purple?
      color: #563d7c;
      text-decoration: none;
      background-color: transparent;
    }

    & > .active > a, & > .active:hover > a, & > .active:focus > a {
      font-weight: 700;
    }

    ul.nav {
      display: none;
    }

    & > .active > ul.nav {
      display: block;
    }
  }
}

#policy-editor-scheduled-action-modal .ninja-editor {
  display: flex;
  max-height: calc(~"100% - 60px");
  min-height: 0;
  form {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    .modal-body {
      min-height: 0;
      flex: 1;
      overflow-y: auto;
    }
  }
}

#policy-editor-backup-modal .ninja-editor {
    display: flex;
    width: 670px;
    max-height: calc(~"100% - 60px");
    form {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-height: 0;
        max-width: 100%;
        .modal-body {
            min-height: 0;
            flex: 1;
            overflow-y: auto;
        }
    }

    .nav > li.active > a {
        color: @ninja-dark;
        background: @ninja-white;
        border-bottom: solid @ninja-white 1px;
    }

    #common-folder-list {
        margin-left: 20px;
    }
    span.common-folder {
        padding: 5px;
    }

    #backupFiltersTab {
        .filters-option {
            font-size: 16px;
            padding-left: 10px;
        }
        .filters-caption {
            padding-left: 35px;
        }
        .filters {
            margin: 5px;
        }
    }

    #backupSourceTab {
        .partitions-option {
            font-size: 16px;
            padding-left: 10px;
        }
        .partitions-caption {
            padding-left: 35px;
            margin-bottom: 10px;
        }
        .partitions {
            margin: 10px;
        }

        #custom-paths {
            margin: 30px;
        }

        input.custom-paths {
            margin-top: 5px;
            width: 500px;
        }

        #selected-partitions-input {
            margin-top: 5px;
            margin-left: 35px;
            width: 500px;
        }

        ul.file-browser {
            list-style-type: none;
        }

        ul.file-browser li {
            padding: 5px 0px 5px 0px;
            font-size: 14px;
        }
    }

    #backupDestinationTab {
        .destinations-option {
            font-size: 16px;
            padding-left: 10px;
        }

        .destinations-caption {
            padding-left: 35px;
            margin-bottom: 10px;
        }
    }
}

.flex-aside-editor {
  height: 100vh;
  width: 100vw;
  min-width: 1024px;
  display: flex;
  flex-direction: column;

  .ninja-header {
    display: flex;
    width: 100%;
    .container {
      display: flex;
      width: 80%;
      padding: 0;
      #ninja-header-logo {
        margin: 5px 0 0;
      }
    }
  }

  .ninja-editor {
    display: flex;
    flex: 1;
    justify-content: center;
    .editor-container {
      display: flex;
      width: 80%;
      height: calc(~"100% - 50px"); 
      flex-direction: column;
      padding: 0;
      .header {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        min-height: 45px;
        .action-buttons {
          display: flex;
          align-items: center;
        }
      }
      .editor-content {
        display: flex;
        flex: 1;
        min-height: 0;
        margin: 15px 0 0;
        aside {
          display: flex;
          min-height: 0;
          min-width: 15%;
          nav {
            flex: 1;
            min-height: 0;
            margin: 0;
            ul > li {
              padding-right: 30px;
            }
            li {
              margin: 0;

              &:not(:last-child) {
                margin-bottom: 15px;
              }

              &.active {
                &.parent-menu {
                  .menu_arrow {
                    color: @ninja-blue;
                  }
                }

                > a {
                  color: @ninja-blue;
                  font-weight: 700;

                  svg path {
                    fill: @ninja-blue;
                  }
                }
              }

              &:not(.active):hover {
                > a {
                  color: @ninja-dark;

                  svg path {
                    fill: @ninja-dark;
                  }
                }

                &.parent-menu {
                  .menu_arrow {
                    color: @ninja-dark;
                  }
                }
              }

              a {
                display: flex;
                align-items: center;
                padding: 0;
                color: @ninja-medium;
                font-size: 13px;
                font-weight: 500;

                svg {
                    margin-right: 10px;
                    width: 18px;

                    path {
                        fill: @ninja-medium;
                    }
                }
              }

              &.parent-menu {
                width: 220px;
                &.open {
                  a {
                    background-color: transparent;
                  }

                  > ul {
                    display: block;
                  }

                  .menu_arrow {
                    transform: rotate(180deg);
                  }
                }
                .menu_arrow {
                  color: @ninja-medium;
                  cursor: pointer;
                  width: 14px;
                  height: 14px;
                  position: absolute;
                  right: 14px;
                  top: 4px;
                }
              }

              ul {
                display: none;
                margin-top: 10px;
                margin-left: 3px;
                border-left: 1px solid #DDE2E7;
                padding-left: 8px;

                li {
                  padding: 8px;

                  &:not(:last-child) {
                    margin-bottom: 4px;
                  }
                
                }
              }
            }
          }
        }
        .content-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;
          height: auto;
          max-width: 85%;
          overflow: hidden;
          border: 0;
          padding: 0;
          .tab-pane {
            display: none;
            flex-direction: column;
            min-height: 0;
            flex: 0 0 0;
            &.active {
              display: flex;
              flex: 1;
            }
            .editor-actions {
              display: flex;
              justify-content: flex-end;
              min-height: 33px;
              padding: 0 0 15px;
              background-color: #ffffff;
              > *:not(:last-child) {
                position: relative;
                margin-right: 12px;
              }
            }
            .flex-ten {
              flex: .1;
            }
            .flex-twenty {
              flex: .2;
            }
            .flex-thirty {
              flex: .3;
            }
            .flex-forty {
              flex: .4;
            }
            .flex-fifty {
              flex: .5;
            }
            .col-container {
              display: flex;
              flex: 1;
              flex-direction: column;
              overflow-y: auto;
              .row {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding: 15px 15px 15px 30px;
                min-height: 70px;
                .col-1 {
                  max-width: 250px;
                  flex: .3;
                }
                .col-2 {
                  flex: .7;
                  > * {
                    max-width: 500px;
                  }
                  p:last-child {
                    margin: 0;
                  }
                }
                .col-group {
                  display: flex;
                  &:not(:last-of-type) {
                    padding-bottom: 10px;
                  }
                  > *:not(.checkbox):not(.flex-full):not(.flex-forty):not(.flex-twenty) {
                    flex: .3;
                  }
                  &.header-right:before {
                    content: '';
                    flex: .55;
                  }
                  p, .checkbox {
                    margin: 0;
                  }
                  > .checkbox.solo {
                    flex: .02;
                    & ~label {
                      display: flex;
                      align-items: center;
                      font-weight: 500;
                      padding-left: 15px;
                      margin-bottom: 0;
                      flex: .98 !important;
                    }
                  }
                }
              }
            }
            .second-level-nav.nav.nav-tabs {
              padding: 7.5px 0;
              display: flex;
              justify-content: flex-start;
              padding-left: 15px;
              li {
                display: flex;
                align-content: center;
                justify-content: center;
                margin: 0 0 0 15px;
                &.active > a,
                &.active > span {
                  color: @ninja-dark;
                  font-weight: 700;
                }
                &:not(.active):hover > a,
                &:not(.active):hover > span {
                  background: none;
                  color: @ninja-medium;
                }
                a, span {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  padding: 0;
                  border: 0;
                  color: @ninja-medium;
                  font-size: 13px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}

.list-container {
  display: flex;
  flex: 1;
  min-height: 0;
  width: 100%;
  .horizontal-tiles {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    .horizontal-tile {
      display: flex;
      align-items: center;
      min-height: 70px;
      padding: 15px 15px 15px 30px;
      .action-links {
        opacity: 0.01;
        transition: opacity .2s;
        &.with-min-dimensions {
          min-width: 90px;
          min-height: 1px;
        }
      }
      &:hover .action-links {
        opacity: 1;
      }
      &.small-tile {
        min-height: 50px;
      }
      &:last-child:not(:first-child) {
        border: 0;
      }
      > *:not(:first-child) {
        margin-left: 15px;
      }
      .checkbox {
        flex: .02;
      }
    }
  }
}

.ninja-editor.system-tray-editor {
  .rst__rowLabel {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-right: 20px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#react-portal .modal {
  .modal-title {
    span {
      word-wrap: break-word;
    }
  }
}

.header-contains-logo {
    position: relative;
    padding-left: 38px;
    > img {
        position: absolute;
        top: 0;
        left: 0;
        height: 26px;
        width: 26px;
    }
}

.ninja-editor-header {
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ninja-header-logo {
        margin: 5px 0 0 20px;
        height: 24px;
    }

    .buttons-container {
        margin-right: 20px;
    }

    button {
        text-align: center;
        margin-left: 10px;
    }
}

.script-editor-container {
    background-color: @script-editor-form-grey;
    width: 100%;

    label {
      color: @ninja-medium;
    }

    .Select {
        z-index: 100;
    }

    #script-editor-properties {
        overflow-y: auto;
        height: calc(~"100vh - 49px");
        background-color: @script-editor-form-grey;

        .form-control {
          color: @ninja-black;
        }

        .react-select__single-value,
        .react-select__input {
          color: @ninja-black
        }

        span.help-block {
            color: #a94442;
            margin: 0;
        }
        
        #parameter-editor {
            button {
                padding: 0;
                border: 0;

                &.delete-parameter {
                    color: @ninja-medium;
                    background-color: @ninja-white;
                    :hover {
                        color: @ninja-dark;
                    }   
                }

                &.add-parameter {
                    color: @ninja-white;
                    :hover {
                        color: @ninja-light;
                    }  
                }
            }

            .code-block {
                padding: 0.5rem 1rem;
                margin-bottom: .5rem;
                background-color: white;
                cursor: move;
                overflow-wrap: break-word;
            }
        }

        .form-group > div {
            padding: 5px
        }

        .rstcustom__node {
            .rstcustom__nodeContent {
                width: 100%;
    
                .rstcustom__rowContents { 
                    padding: 0 5px;
                    width: 100%;
                    
                    .rstcustom__rowLabel {
                        width: 90%;
                        padding-right: 0;
    
                        .rstcustom__rowTitle {
                            code {
                                text-overflow: ellipsis;
                                display: inline-block;
                                overflow: hidden;
                                width: 100%;
                            }
                        }
                    }
    
                    .rstcustom__rowToolbar {
                        cursor: pointer;
    
                        a {
                            color: @ninja-medium;
                            :hover {
                                color: @ninja-dark;
                            }
                        }
                    }
                }
            }
        }
        
        .rstcustom__highlight {
            background: inherit;
        }
    }
}

.advanced-option-checkbox {
    padding-top:6px;
    position: relative;
}

#full-screen-modal .editor-container {
  height: 100%;
}

#full-screen-modal .modal-content {
  border-radius: 0;
}

.col-group-column-header {
  display: flex;
  &.header-right:before {
    content: '';
    flex: 0.33 1;
  }
  .col-header-1 {
    flex: 0.17 1 !important;
  }
  .col-header-2 {
    flex: 0.17 1 !important;
  }
  .col-header-3 {
    flex: 0.11 1 !important;
  }
  .col-header-4 {
    flex: 0.11 1 !important;
  }
}

.no-outline:focus {
    outline: 0;
}

#organization-virtualization-tab {

  .editor-actions {
    .dropdown-menu {
      left: unset;
      right: 10px;
      li {
        span {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

#host-table {
  .table-row{
    height: 35px;

    .btn-link{
        visibility: hidden;
    }

    &:hover{
      cursor: pointer;
      .btn-link{
          visibility: visible;
      }
    }
  }
  #loadin-indicator{
    margin-top: 40px;
  }
}

.vm-list {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px;
  border: 1px solid @ninja-light;
  border-radius: 3px;
  .vm-item {
    display: flex;
    justify-content: space-between;
    padding: 2px;
    .btn-link {
      display: none;
    }
  }
  .vm-item:hover{
    cursor: pointer;
    .btn-link {
      display: block;
    }
  }
}

#delegate-table {
  height: 300px;

  .not-allowed {
    cursor: not-allowed !important;
  }
  .list-group-item {
    border-radius: 0;
  }

  .delegate-device {
    display: flex;
    flex: 1;
    
    div:nth-child(1) {
      display: flex;
      max-width: 40;
      margin: 0px 20px 0px 10px;
      align-items: center;
    }
  
    div:nth-child(2) {
      flex: 1;
      word-break: break-all;
      text-align: left;
      align-items: center;
      margin-top: 5px;
    }
  
    div:nth-child(3) {
      margin-left: 10px;
      align-self: flex-start;
      justify-self: flex-end;
      margin-top: 5px;
      text-align: right;
    }
  }
  .selected {
    background-color: @ninja-light;
  }
}

.input-with-button {
  position: relative;
  .input {
    background-color: @white;
    border: 1px solid @ninja-light;
    border-radius: 3px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    width: 100%;
    font-size: 14px;
    text-align: left;
    min-height: 34px;
  }
  .btn-link {
    position: absolute;
    top: 8px;
    right: 12px;
  }
}

.has-error {
  .input-with-button {
    .input {
      border-color: @ninja-red;
    }
  }
}

.modal-footer-message {
  em {
    font-size: 12px;
  }
  .success {
    color: @ninja-green;
  }
  .error {
    color: @ninja-red;
  }
}

.tab-error {
  a {
    color: @ninja-red !important;
  }
}
