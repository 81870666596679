.splashtop-content {
  margin-left: 30px;
}

.splashtop-instructions {
  font-size: 14px;
  margin-bottom: 10px;
}

i.splashtop-icon {
  margin-right: 10px;
}
