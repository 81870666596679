/* TOPBAR */

.topbar-quick-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.topbar-quick-links > span {
  padding-left: 20px;
  width: 41px;
  display: inline-flex;
  justify-content: center;
}

.topbar-quick-links {
  .topbar-link {
    color: @ninja-medium;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: @ninja-dark;
    }
  }
}

.topbar-quick-links .dropdown-menu li {
  max-width: 300px;

  a, .btn-link {
    display: block;
    width: auto !important;
    max-width: 300px;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  min-width: 250px;
}

.dropdown-menu .divider {
  margin: 0;
}

.dropdown-menu.pull-right {
  right: 10px;
}

.dropdown-menu > li {
  .btn-link, a {
    border-radius: 3px;
    color: inherit;
    line-height: 25px;
    padding: 7px 24px;
    text-align: left;
    font-weight: normal;
  }
}

.dropdown-menu > .active {
  > .btn-link, > a, > a:focus, > a:hover {
    color: #fff;
    text-decoration: none;
    background-color: @ninja-light;
    outline: 0;
  }
}

.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}

/* Tab Navigation */
#tab-header {
  display: flex;
  align-items: center;
  header {
    line-height: 1;
    flex: 1;
    display: flex;
    padding-bottom: 15px;
    > div:not(.tab-links-container) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &:not(.has-sub-nav) {
      .tab-links-container {
        justify-content: flex-end;
      }
      .tab-actions {
        margin: 0;
      }
    }
    &.has-sub-nav,
    &.has-actions {
      flex-direction: column;
      padding-bottom: 20px;
      .tab-links-container:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        border-radius: 2px;
        background-color: @ninja-light;
      }
      .tab-actions {
        margin: 8px 0 0;
      }
    }
    > div {
      position: relative;
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }
  > *:not(:first-child) {
    margin: 0 0 0 4px;
  }
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  .tab-actions {
    display: flex;
    align-items: center;
    
    a, button {
      display: flex;
      line-height: 1;
    }
    ul {
      padding-left: 0;
    }
    > .icon-actions {
      display: flex;
      
      > div {
        margin-left: 15px;
        padding: 0;

        .hover-dropdown-content-container {
          top: 13px;
        }
      }
    }
    > .actions {
      display: flex;
      margin-top: auto;
      > div {
        padding: 0 0 8px 10px;
      }
    }
    > form {
      padding: 0 0 0 10px;
    }
  }
  .tab-info {
    display: inline-flex;
    color: @ninja-medium;
    p {
      margin: 0;
    }
    .subtab-help-link {
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .tab-links {
    display: flex;
    margin: 8px 0 0;
    padding: 0;
    a, button {
      color: @ninja-medium;
      &:hover {
        color: @ninja-dark;
      }
    }
    &:hover li:hover {
      > a.hover-dropdown-title, 
      > button.hover-dropdown-title {
        color: @ninja-dark;
      }
      > a:not(.hover-dropdown-title),
      > button:not(.hover-dropdown-title),
      .hover-dropdown-content {
        color: @ninja-medium;
        &:hover {
          color: @ninja-dark;
        }
        .active a, .active button {
          cursor: default;
          color: @ninja-black;
          background-color: @ninja-white;
        }
      }
    }
    li {
      position: relative;
      &:not(:last-child) {
        margin-right: 15px;
      }
      a, button, span {
        z-index: 1500;
        position: relative;
      }
      span {
        cursor: not-allowed;
      }
      .hover-dropdown-title {
        z-index: 1500;
      }
      .hover-dropdown-content-container {
        // overrides
        z-index: 1499;
        left: -5px;
        top: calc(~"100% - 1px");
      }
      &.active > a, &.active > button, &.active > span {
        color: @ninja-black !important;
        cursor: default;
        &:after {
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          height: 2px;
          border-radius: 2px;
          background-color: @ninja-dark;
        }
      }
      &.active > a:after, &.active > span:after {
        bottom: -6px;
      }
      &.active > button:after {
        bottom: -8px;
      }
      &:hover {
        > a.hover-dropdown-title:after, 
        > button.hover-dropdown-title:after {
          content: none;
        }
      }
    }
  }
}

#popover-positioned-bottom .popover-content {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  max-width: 500px;
  li:not(:first-of-type) {
    padding-top: 2px;
  }
  li:not(:last-of-type) {
    padding-bottom: 2px;
  }
  .popover-title,
  .popover-content {
      white-space: nowrap;
  }
  .popover-content ul {
      list-style: none;
      margin: 0;
      padding: 0;
  }
}


// TODO: merge this with creds/node-apprvs
.tab-links-container {
  .tab-links {
    margin: 0 0 15px 0;
    padding: 0;
    display: flex;
    > li {
      margin-right: 15px;
      color: @ninja-medium;
      > span {
        cursor: pointer;
      }
      &.active {
        position: relative;
        color: @ninja-black;
        > span:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: -6px;
          left: 0;
          height: 2px;
          border-radius: 2px;
          background-color: @ninja-dark;
        }
      }
    }
  }
}

.custom-nav-tabs {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .nav-tabs {
    border-bottom: none;
    li {
      margin-right: 15px;
      color: @ninja-medium;
      
      > span, > a {
        margin: 0;
        padding: 0;
        border: none;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0);
        &:focus {
          outline: none;
        }
      }
      &.active {
        position: relative;
        border: none;
        color: @ninja-black;
        &.tab-error {
          > span:after, a:after {
            background-color: @ninja-red;
          }
        }
        span, a {
          border: none;
        }
        > span:after, a:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: -6px;
          left: 0;
          height: 2px;
          border-radius: 2px;
          background-color: @ninja-dark;
        }
      }
      &.focus {
        outline: none;
      }
    }
  }
  .tab-content {
    margin-top: 20px;
    display: flex;
    flex: 1;
    
    .active {
      display: block;
      flex: 1;
    } 
  }
}
