#rdp-connect {
  section {
    display: flex;
    margin-bottom: 15px;
  }

  label {
    margin-top: 7.5px;
    flex: .3;
    text-align: left;
  }

  .rdp-fields {
    flex: 1;

    .rdp-field {
      text-align: left;
      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }

  .icheckbox_square-blue {
    margin-right: 10px;
  }

  #rdp-connection-settings {
    .tab {
      padding-right: 100px;

      section label {
        flex: .25;
      }

      &:not(.active) {
        display: none;
      }
    }
    .rdp-fields label {
      flex: .4;
    }
  }
}
