/* FLOT CHART  */

.flot-chart {
  display: block;
  height: 200px;
}

.widget .flot-chart.dashboard-chart {
  display: block;
  height: 120px;
  margin-top: 40px;
}

.flot-chart.dashboard-chart {
  display: block;
  height: 180px;
  margin-top: 40px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.flot-chart-pie-content {
  width: 200px;
  height: 200px;
  margin: auto;
}

.jqstooltip {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgb(43, 48, 58);
  background-color: rgba(43, 48, 58, 0.8);
  color: white;
  text-align: left;
  white-space: nowrap;
  z-index: 10000;
  padding: 5px 5px 5px 5px;
  min-height: 22px;
  border-radius: 3px;
}

.jqsfield {
  color: white;
  text-align: left;
}

.fh-150 {
  height: 150px;
}

.fh-200 {
  height: 200px;
}

.h-150 {
  min-height: 150px;
}

.h-200 {
  min-height: 200px;
}

.h-300 {
  min-height: 300px;
}

.w-150 {
  min-width: 150px;
}

.w-200 {
  min-width: 200px;
}

.w-300 {
  min-width: 300px;
}

.legendLabel {
  padding-left: 5px;
}

.stat-list li:first-child {
  margin-top: 0;
}

.stat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stat-percent {
  float: right;
}

.stat-list li {
  margin-top: 15px;
  position: relative;
}

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid @ninja-medium;
  background-color: @ninja-light !important;
}

.show-grid {
  margin: 15px 0;
}

// FLOTS STYLING

.mini-flot{
    float: left;
    width: 70px;
    height: 45px;
    margin-right: 10px;
    border: 1px solid @table-border-color;
    border-radius: 1px;
}

.md-flot{
    float: left;
    width: 120px;
    height: 80px;
    margin-right: 10px;
    border: 1px solid @table-border-color;
    border-radius: 1px;
}

.flot{
	width: 100%;
	height: 150px;
	border: 1px solid @table-border-color;

	&.disk-active-time-chart{
		height: 100px;
	}

	&.disk-transfer-rate-chart{
		height: 50px;
	}

    &.disk-usage-chart{
        height: 25px;
    }

	// &.network-interface-chart{
 //    	margin-bottom: 20px;
 //    }
}

.flot-danger {
    border-color: @state-danger-border;
}

.flot-warning {
    border-color: @state-warning-border;
}

.flot-warning-light {
    border-color: darken(@state-warning-border, -15%);
}

.flot-success {
    border-color: @state-success-border;
}

.flot-success {
    border-color: @state-success-border;
}

.flot-success-light {
    border-color: darken(@state-success-border, -25%);
}

.flot-info {
    border-color: @state-info-border;
}

.flot-purple{
	border-color: @purple;
}

.flot-labels{
	font-size: .7em;
}
.flot + .flot-labels{
	// margin-top: -2em; margin-bottom: 1.5em;
}

.flot-wrap{
    position: relative;

    .flot-labels{
        position: absolute;
        top: 0; left: 0; right: 0;
    }
    .flot-labels-bottom{
        bottom: 0; top: auto;
    }

    padding: 1em 0;

    &.flot-wrap-horizontal{
        margin-bottom: 1.5em;
    }

    &.flot-wrap-vertical{
        margin-bottom: 0.5em;
    }
}

.widget-body {
    .flot-labels{
        padding: 0 1em;
        margin-top: -1.5em;
    }
    .flot-labels:first-child{
        padding-top: 1em;
        padding-bottom: 0;
    }
    .flot.flot-info{
        border: 0;
    }
}

// END FLOTS STYLING
