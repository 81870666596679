.btn-link {
  color: @ninja-blue-saturated;
  padding: 0;
  margin: 0;
  border: 0;
}

.block {
  display: block;
}

.clear {
  display: block;
  overflow: hidden;
}

.overflow-visible-important {
  overflow: visible !important;
}

a {
  cursor: pointer;
}

a:hover, a:focus {
  text-decoration: none;
}

.border-bottom {
  border-bottom: 1px solid @border-color !important;
}

.font-bold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.font-italic {
  font-style: italic;
}

.b-r {
  border-right: 1px solid @border-color;
}

.hr-line-dashed {
  border-top: 1px dashed @border-color;
  color: #ffffff;
  background-color: #ffffff;
  height: 1px;
  margin: 20px 0;
}

.hr-line-solid {
  border-bottom: 1px solid @border-color;
  background-color: rgba(0, 0, 0, 0);
  border-style: solid !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

video {
  width: 100% !important;
  height: auto !important;
}

/* MODAL */
#full-screen-modal {
  min-width: 1024px;
  padding-left: 0px !important;
  ~ reach-portal {
    > [data-reach-listbox-popover], > [data-reach-tooltip] {
      z-index: 2050;
    }
  }
}

.modal-content {
  background-clip: padding-box;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0 none;
  position: relative;
}

.modal-dialog {
  z-index: 2200;
  min-width: 540px;
}

.modal-body {  
  padding: 20px 30px 30px;
}

.inmodal .modal-body {
  background-color: @ninja-white;
  &.text-left {
    text-align: left !important;
  }
}

.inmodal .modal-header {
  padding: 30px 15px;
  text-align: center;
}

.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.inmodal .modal-title {
  font-size: 26px;
}

.inmodal .modal-icon {
  font-size: 84px;
  color: @ninja-light;
}

.modal-footer {
  margin-top: 0;
}

/* WRAPPERS */
.ibox-content h1, .ibox-content h2, .ibox-content h3, .ibox-content h4, .ibox-content h5,
.ibox-title h1, .ibox-title h2, .ibox-title h3, .ibox-title h4, .ibox-title h5 {
  margin-top: 5px;
}


/* TABLES */
.table-bordered {
  border: 1px solid @ninja-light;
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  background-color: @ninja-white;
  border-bottom-width: 1px;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid @ninja-light;
}

.table > thead > tr > th {
  border-bottom: 1px solid @ninja-light;
  vertical-align: bottom;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top: 1px solid @border-color;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}

/* PANELS */
.panel.blank-panel {
  background: none;
  margin: 0;
}

.nav.nav-tabs li {
  background: none;
  border: none;
}

.nav-tabs > li > a {
  color: @ninja-medium;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  background-color: @ninja-light;
  color: @text-color;
}

.ui-tab .tab-content {
  padding: 20px 0;
}

/* GLOBAL  */

.no-padding {
  padding: 0 !important;
}

.no-borders {
  border: none !important;
}

.no-margins {
  margin: 0 !important;
}

.no-top-border {
  border-top: 0 !important;
}

.ibox-content.text-box {
  padding-bottom: 0;
  padding-top: 15px;
}

.border-all-sides {
  border: 1px solid @border-color;
}

.border-left-right {
  border-left: 1px solid @border-color;
  border-right: 1px solid @border-color;
}

.border-top-bottom {
  border-top: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
}

.border-left {
  border-left: 1px solid @border-color;
}

.border-right {
  border-right: 1px solid @border-color;
}

.border-top {
  border-top: 1px solid @border-color;
}

.border-bottom {
  border-bottom: 1px solid @border-color;
}

.border-size-sm {
  border-width: 3px;
}

.border-size-md {
  border-width: 6px;
}

.border-size-lg {
  border-width: 9px;
}

.border-size-xl {
  border-width: 12px;
}

.full-width {
  width: 100% !important;
}

.link-block {
  font-size: 12px;
  padding: 10px;
}

.nav.navbar-top-links .link-block a {
  font-size: 12px;
}

.link-block a {
  font-size: 10px;
  color: inherit;

}

body.mini-navbar .branding {
  display: none;
}

img.circle-border {
  border: 6px solid #FFFFFF;
  border-radius: 50%;
}

.branding {
  float: left;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  padding: 17px 20px;
  text-align: center;
  background-color: @navy;
}

.login-panel {
  margin-top: 25%;
}

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.icons-box .infont a i {
  font-size: 25px;
  display: block;
  color: @text-color;
}

.icons-box .infont a {
  color: @ninja-medium;
}

.icons-box .infont a {
  padding: 10px;

  margin: 1px;
  display: block;
}

.ui-draggable .ibox-title {
  cursor: move;
}

.breadcrumb {
  background-color: #ffffff;
  padding: 0;
  margin-bottom: 0;
}

.breadcrumb > li a {
  color: inherit;
}

.breadcrumb > .active {
  color: inherit;
}

code {
  background-color: @ninja-white;
  border-radius: 4px;
  color: @ninja-red;
  font-size: 90%;
  padding: 2px 4px;
  white-space: nowrap;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}

.ibox:after, .ibox:before {
  display: table;
}

.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #fff;
  border-color: @border-color;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
}

.ibox-content {
  background-color: #fff;
  color: inherit;
  padding: 15px 20px 20px 20px;

  border-color: @border-color;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid @border-color;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

table.table-mail tr td {
  padding: 12px;
}

.table-mail .check-mail {
  padding-left: 20px;
}

.table-mail .mail-date {
  padding-right: 20px;
}

.star-mail, .check-mail {
  width: 40px;
}

.unread td a, .unread td {
  font-weight: 600;
  color: inherit;
}

.read td a, .read td {
  font-weight: normal;
  color: inherit;
}

.unread td {
  background-color: @ninja-white;
}

.ibox-content {
  clear: both;
}

.ibox-heading {
  background-color: @ninja-white;
  border-bottom: none;
}

.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}

.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: left;
}

.ibox-title .label {
  float: left;
  margin-left: 4px;
}

.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: relative;
  padding: 0;
  text-align: right;
}

.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: @ninja-light;
}

.ibox-tools a.btn-primary {
  color: #fff;
}

.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
}

.ibox .ibox-tools.open > .dropdown-menu {
  left: auto;
  right: 0;
}

/* BACKGROUNDS */

.gray-bg, .bg-muted {
  background-color: @gray;
}

.white-bg {
  background-color: #ffffff;
}

.navy-bg {
  background-color: @navy;
  color: #ffffff;
}

.blue-bg {
  background-color: @blue;
  color: #ffffff;
}

.lazur-bg {
  background-color: @lazur;
  color: #ffffff;
}

.yellow-bg {
  background-color: @yellow;
  color: #ffffff;
}

.red-bg {
  background-color: @red;
  color: #ffffff;
}

.black-bg {
  background-color: @ninja-black;
}

.panel-primary {
  border-color: @navy;
}

.panel-primary > .panel-heading {
  background-color: @navy;
  border-color: @navy;
}

.panel-success {
  border-color: @blue;
}

.panel-success > .panel-heading {
  background-color: @blue;
  border-color: @blue;
  color: #ffffff;
}

.panel-info {
  border-color: @lazur;
}

.panel-info > .panel-heading {
  background-color: @lazur;
  border-color: @lazur;
  color: #ffffff;
}

.panel-warning {
  border-color: @yellow;
}

.panel-warning > .panel-heading {
  background-color: @yellow;
  border-color: @yellow;
  color: #ffffff;
}

.panel-danger {
  border-color: @red;
}

.panel-danger > .panel-heading {
  background-color: @red;
  border-color: @red;
  color: #ffffff;
}

.progress-bar {
  background-color: @navy;
}

.progress-bar-navy-light {
  background-color: @ninja-green;
}

.progress-bar-success {
  background-color: @blue;
}

.progress-bar-info {
  background-color: @lazur;
}

.progress-bar-warning {
  background-color: @yellow;
}

.progress-bar-danger {
  background-color: @red;
}

.panel-title {
  font-size: inherit;
}

.jumbotron {
  border-radius: 6px;
  padding: 40px;
}

.jumbotron h1 {
  margin-top: 0;
}

/* COLORS */

.text-success {
  color: @ninja-green;
}

.text-danger {
  color: @ninja-red !important;

  & div, span, label {
    color: @ninja-red !important;
  }
}

.text-warning {
  color: @yellow;
}

.text-accent {
  color: @ninja-blue-saturated;
}

.text-muted {
  color: @gray-mid-light;
}

.text-white {
  color: #ffffff;

  &.btn:hover {
    color: #ffffff;
    opacity: 0.6;
  }
}

.simple_tag {
  background-color: @ninja-white;
  border: 1px solid @ninja-light;
  border-radius: 2px;
  color: inherit;
  font-size: 10px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 12px;
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
  -moz-box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
  box-shadow: 0 0 3px 0 rgba(145, 145, 145, 1);
}

.full-height {
  height: 100%;
}

.fh-breadcrumb {
  height: calc(~"100% - 196px");
  margin: 0 -15px;
  position: relative
}

.fh-no-breadcrumb {
  height: calc(~"100% - 99px");
  margin: 0 -15px;
  position: relative
}

.fh-column {
  background: #fff;
  height: 100%;
  width: 240px;
  float: left
}

.modal-backdrop {
  background-color: #FFFFFF !important;
  opacity: 0.01 !important;
  z-index: 2040 !important;
}

.modal {
  z-index: 2050 !important;
}

.spiner-example {
  height: 200px;
  padding-top: 70px;
}

/* MARGINS & PADDINGS */

.p-xxs {
  padding: 5px;
}

.p-xs {
  padding: 10px;
}

.p-sm {
  padding: 15px;
}

.p-m {
  padding: 20px;
}

.p-md {
  padding: 25px;
}

.p-lg {
  padding: 30px;
}

.p-xl {
  padding: 40px;
}

.p-w-xs {
  padding: 0 10px;
}

.p-w-sm {
  padding: 0 15px;
}

.p-w-m {
  padding: 0 20px;

}

.p-w-md {
  padding: 0 25px;

}

.p-w-lg {
  padding: 0 30px;

}

.p-w-xl {
  padding: 0 40px;

}

.p-h-xxs {
  padding: 5px 0;
}

.p-h-xs {
  padding: 10px 0;
}

.p-h-sm {
  padding: 15px 0;

}

.p-h-m {
  padding: 20px 0;

}

.p-h-md {
  padding: 25px 0;

}

.p-h-lg {
  padding: 30px 0;

}

.p-h-xl {
  padding: 40px 0;

}

.m-xxs {
  margin: 2px 4px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-auto {
  margin-left: auto;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.space-15 {
  margin: 15px 0;
}

.space-20 {
  margin: 20px 0;
}

.space-25 {
  margin: 25px 0;
}

.space-30 {
  margin: 30px 0;
}

.sides-15 {
  margin-left: 15px;
  margin-right: 15px;
}

// IMAGES SIZE

.img-sm {
  width: 32px;
  height: 32px;
}

.img-md {
  width: 64px;
  height: 64px;
}

.img-lg {
  width: 96px;
  height: 96px;
}

// BORDER RADIUS

.b-r-xs {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}

.b-r-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.b-r-md {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.b-r-lg {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}

.b-r-xl {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

// Fullscreen functions

.fullscreen-ibox-mode .animated {
  animation: none;
}

body.fullscreen-ibox-mode {
  overflow-y: hidden;
}

.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}

.ibox.fullscreen .collapse-link {
  display: none;
}

.ibox.fullscreen .ibox-content {
  min-height: calc(~"100% - 48px");
}

// Bootstrap issue in 3.3.0 with modal https://github.com/twbs/bootstrap/issues/9855
// Fix
body.modal-open {
  padding-right: inherit !important;
}

// Fix modal in Safari browser
body.modal-open .wrapper-content.animated {
  -webkit-animation: none;
}

// Fix for modal backdrop with animate.css
body.modal-open .animated {
  animation-fill-mode: initial;

  // We need to disable the z-index as this prevents showing a SmartMessageBox in modals
  // z-index: inherit
}

/* Show profile dropdown on fixed sidebar */
body.mini-navbar.fixed-sidebar .profile-element, .block {
  display: block !important;
}

body.mini-navbar.fixed-sidebar .nav-header {
  padding: 33px 25px;
}

body.mini-navbar.fixed-sidebar .logo-element {
  display: none;
}

// Remove animation on fullscreen video

.fullscreen-video .animated {
  animation: none;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.zero-min-height {
  min-height: 0;
}

.label {
  background-color: @light-gray;
  color: @label-badget-color;
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}

/*buttons*/
.btn {
  border-radius: 3px;
}

.btn-primary {
  background-color: @ninja-blue-saturated;
  border-color: @ninja-blue-saturated;
  color: #FFFFFF;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
  background-color: darken(@ninja-blue-saturated, 3%);
  border-color: darken(@ninja-blue-saturated, 3%);
  color: #FFFFFF;
}

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: lighten(@ninja-blue-saturated, 4%);
  border-color: lighten(@ninja-blue-saturated, 4%);
}

.btn-success {
  background-color: @ninja-blue-saturated;
  border-color: @ninja-blue-saturated;
  color: #FFFFFF;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success, .btn-success:active:focus, .btn-success:active:hover, .btn-success.active:hover, .btn-success.active:focus {
  background-color: darken(@ninja-blue-saturated, 3%);
  border-color: darken(@ninja-blue-saturated, 3%);
  color: #FFFFFF;
}

.btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success.active[disabled], fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: lighten(@ninja-blue-saturated, 4%);
  border-color: lighten(@ninja-blue-saturated, 4%);
}

.btn-default {
  color: inherit;
  background: white;
  border: 1px solid @border-color;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default, .btn-default:active:focus, .btn-default:active:hover, .btn-default.active:hover, .btn-default.active:focus {
  color: inherit;
  border: 1px solid @ninja-light;
}

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default.active[disabled], fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  color: @ninja-medium;
}

.btn-warning {
  background-color: @yellow;
  border-color: @yellow;
  color: #FFFFFF;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning, .btn-warning:active:focus, .btn-warning:active:hover, .btn-warning.active:hover, .btn-warning.active:focus {
  background-color: darken(@yellow, 3%);
  border-color: darken(@yellow, 3%);
  color: #FFFFFF;
}

.btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning.active[disabled], fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: lighten(@yellow, 4%);
  border-color: lighten(@yellow, 4%);
}

.btn-danger {
  background-color: @red;
  border-color: @red;
  color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:hover, .btn-danger.active:focus {
  background-color: darken(@red, 3%);
  border-color: darken(@red, 3%);
  color: #FFFFFF;
}

.btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger.active[disabled], fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: lighten(@red, 4%);
  border-color: lighten(@red, 4%);
}

.btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
  //color: @navy;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
  background-image: none;
}

.btn-link.disabled, .btn-link.disabled:hover, .btn-link.disabled:focus, .btn-link.disabled:active, .btn-link.disabled.active, .btn-link[disabled], .btn-link[disabled]:hover, .btn-link[disabled]:focus, .btn-link[disabled]:active, .btn-link.active[disabled], fieldset[disabled] .btn-link, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus, fieldset[disabled] .btn-link:active, fieldset[disabled] .btn-link.active {
  color: @ninja-light;
}

.btn-white {
  color: inherit;
  background: white;
  border: 1px solid @border-color;
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white, .btn-white:active:focus, .btn-white:active:hover, .btn-white.active:hover, .btn-white.active:focus {
  color: inherit;
  border: 1px solid @ninja-light;
}

.btn-white:active, .btn-white.active {
  box-shadow: 0 2px 5px @ninja-white inset;
}

.btn-white:active, .btn-white.active, .open .dropdown-toggle.btn-white {
  background-image: none;
}

.btn-white.disabled, .btn-white.disabled:hover, .btn-white.disabled:focus, .btn-white.disabled:active, .btn-white.disabled.active, .btn-white[disabled], .btn-white[disabled]:hover, .btn-white[disabled]:focus, .btn-white[disabled]:active, .btn-white.active[disabled], fieldset[disabled] .btn-white, fieldset[disabled] .btn-white:hover, fieldset[disabled] .btn-white:focus, fieldset[disabled] .btn-white:active, fieldset[disabled] .btn-white.active {
  color: @ninja-light;
}

.form-control, .form-control:focus, .has-error .form-control:focus, .has-success .form-control:focus, .has-warning .form-control:focus, .navbar-collapse, .navbar-form, .navbar-form-custom .form-control:focus, .navbar-form-custom .form-control:hover, .open .btn.dropdown-toggle, .panel, .popover, .progress, .progress-bar {
  box-shadow: none;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-rounded {
  border-radius: 50px;
}

.btn:focus {
  outline: none !important;
}

/* TOOLTIPS */

.tooltip-inner {
  background-color: @ninja-blue;
}

.tooltip.top .tooltip-arrow {
  border-top-color: @ninja-blue;
}

.tooltip.right .tooltip-arrow {
  border-right-color: @ninja-blue;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: @ninja-blue;
}

.tooltip.left .tooltip-arrow {
  border-left-color: @ninja-blue;
}

/* LIST GROUP */
a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
  background-color: @navy;
  border-color: @navy;
  color: #FFFFFF;
  z-index: 2;
}

.list-group-item-heading {
  margin-top: 10px;
}

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  font-size: 12px;
  line-height: inherit;
}

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}

.no-padding .list-group {
  margin-bottom: 0;
}

.list-group-item {
  background-color: inherit;
  border: 1px solid @border-color;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}


/* INPUTS */
.inline {
  display: inline-block !important;
}

.input-s-sm {
  width: 120px;
}

.input-s {
  width: 200px;
}

.input-s-lg {
  width: 250px;
}

.i-checks {
  padding-left: 0;
}

.form-control, .single-line {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid @ninja-light;
  border-radius: 3px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size: 13px
}

.form-control:hover {
  border-color: darken(@ninja-light, 10%);
}

.form-control:focus, .single-line:focus {
  border-color: @ninja-blue-saturated !important;
}

.has-success .form-control {
  border-color: @ninja-green;
}

.has-warning .form-control {
  border-color: @ninja-yellow;
}

.has-error .form-control {
  border-color: @ninja-red;
}

.has-error.form-control {
  border-color: @ninja-red !important;
}

.has-error .Select-control {
  border-color: @ninja-red;
}

.has-error.Select {
  border-color: @ninja-red !important;
}

.has-success .control-label {
  color: @ninja-green;
}

.has-warning .control-label {
  color: @ninja-yellow;
}

.has-error .control-label {
  color: @ninja-red;
}

.input-group-addon {
  background-color: #fff;
  border: 1px solid @ninja-light;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}

.spinner-buttons.input-group-btn .btn-xs {
  line-height: 1.13;
}

.spinner-buttons.input-group-btn {
  width: 20%;
}

.noUi-connect {
  background: none repeat scroll 0 0 @navy;
  box-shadow: none;
}

.slider_red .noUi-connect {
  background: none repeat scroll 0 0 @red;
  box-shadow: none;
}

// validation
label.error {
  color: @ninja-red;
  display: inline-block;
  margin-left: 5px;
}

.form-control.error {
  border: 1px dotted @ninja-red;
}

/* WIDGETS */

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.widget.style1 h2 {
  font-size: 30px;
}

.widget h2, .widget h3 {
  margin-top: 5px;
  margin-bottom: 0;
}

.widget-text-box {
  padding: 20px;
  border: 1px solid @border-color;
  background: #ffffff;
}

.widget-head-color-box {
  border-radius: 5px 5px 0 0;
  margin-top: 10px;

}

.widget .flot-chart {
  height: 100px;
}

.vertical-align div {
  display: inline-block;
  vertical-align: middle;
}

.vertical-align h2, .vertical-align h3 {
  margin: 0;
}

.no-resize {
  resize: none;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.pointer {
  cursor: pointer !important;
}

.no-pointer {
  cursor: default !important;
}

.fixed-layout {
  display: table !important;
  width: 100% !important;
  table-layout: fixed !important;
}

.small-input-number {
  display: inline;
  width: 80px;
  padding-right: 0;
}

.medium-input-number {
  display: inline;
  width: 150px;
  padding-right: 0;
}

.form-row-height {
  height: 32px;
}

.word-break-all {
  word-break: break-all;
}

#application-announcement  {
  #content {
    display: flex;
    flex: 1;
    background-color: @ninja-light-yellow;
    min-height: 50px;
    #content {
      display: flex;
      flex: 1;
      padding-left: 15px;
      align-items: center;
      overflow: hidden;
      font-size: 14px;
      p {
        margin: 0;
        color: @ninja-medium;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    #action {
      width: 60px;
      background-color: transparent;
      border: none;
      color: @ninja-medium;
      font-size: 16px;
    }
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-basis-auto {
  flex-basis: auto !important
}

.text-normal {
  color: @text-color;
}

.text-underline {
  position: relative;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -3px;
    left: 0;
    height: 2px;
    border-radius: 2px;
    background-color: @ninja-blue-saturated;
  }

  &:focus, &:hover {
    &:after { 
      background-color: @ninja-blue-dark;
    }
  }
}

.app-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
}

.status-border {
  padding-left: 10px;
  border-left: 7px solid @blue;

  &.warning {
    border-left-color: @ninja-yellow;
  }

  &.danger {
    border-left-color: @ninja-red;
  }

  &.good {
    border-left-color: @ninja-green;
  }
}

.border{
  margin: 0px;
  padding: 3px 5px;
  color: @ninja-light;
  background: none;
  border-left-width: 7px;
  border-left-style: solid;
  &.border-right {
    border-left: 0;
    border-right-width: 7px;
    border-right-style: solid;
  }

  // Order of classes is very important
  // danger > warning > info > success
  &.success{
    border-color: @state-success-border;
  }

  &.info{
    border-color: @blue;
  }

  &.warning{
    border-color: @brand-warning;
  }

  &.danger{
    border-color: @state-danger-border;
  }

  &.no-border{
    border-left-width: 0px;
    padding: 0 0;
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.read-only-overlay {
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.outline-none {
  outline: none;
}

.rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.one-row-ellipsis {
  .rows-ellipsis;
  -webkit-line-clamp: 1;
}

.two-rows-ellipsis {
  .rows-ellipsis;
  -webkit-line-clamp: 2;
}

.line-height-initial {
  line-height: initial;
}

.user-select {
  cursor: text;
  user-select: text !important;
}

.copy-text {
  &:hover {
    color: @ninja-medium;
    cursor: copy;
  }
}

.z-10000 {
  z-index: 10000 !important;
}
