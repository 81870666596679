#psa-sub-tab {
  display: flex;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  .psa-logo {
    width: 100%;
    img {
      max-width: 250px;
    }
  }
  .psa-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    min-height: 0;
    .psa-section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid @border-color;
      h3 {
        font-size: 14px;
        color: @ninja-dark;
      }
    }
    .psa-section-body {
      list-style: none;
      margin: 0;
      padding: 0;
      .psa-section-info {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 0;
        color: @gray-light;
        span:first-child, .text-success {
          font-weight: 600;
        }
      }
    }
    .psa-configuration {
      flex: .54;
      display: flex;
      flex-direction: column;
      max-width: 54%;
      .psa-settings {
        margin-top: 15px;
      }
    }
    .psa-ticketing {
      flex: .36;
      display: flex;
      flex-direction: column;
      min-width: 36%;
      .psa-ticket {
        &:not(:hover) .psa-ticket-name {
          .text-ellipsis;
          white-space: pre;
        }
        &:hover .psa-ticket-name {
          word-break: break-all;
          white-space: pre-wrap;
        }
      }
      .list-templates {
        max-height: 300px;
        overflow-y: scroll;
      }
    }
  }
}

#psa-settings-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
	height: 100%;

  .container {
    padding: 0;
  }

  .ninja-header {
    img {
      height: 24px;
      margin-top: 5px;
    }
  }

  .ninja-editor {
    background-color: @white;
  }

  .psa-settings-editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    img {
      max-width: 250px;
    }
    button:not(:first-of-type) {
      margin-left: 5px;
    }
	}
	
}

.psa-filter-select-row {
  text-align: left;
  padding: 0 60px;
  label {
    cursor: pointer;
    display: flex;
    margin: 0;
    > span {
      display: inherit;
      align-items: center;
      padding: 0 15px;
    }
  }
}

#psa-cw-device-mapping,
#psa-cw-account-mapping {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 15px;
  .title {
    justify-content: space-between;
  }
  > div > div > .ReactVirtualized__Table .ReactVirtualized__Table__headerRow {
    border-top-left-radius: 0;
    border-left: none;
  }
  .ReactVirtualized__Grid {
    background-color: @white;
  }
}
.accelo-hostname-container {
  .tooltip-accelo {
    margin: 4px 0 0 5px;
  }

  .accelo-domain-input {
    margin: -5px 0 0 10px;
  }

  .accelo-domain-paragraph {
    margin-top: 3px;
  }

}

.accelo-settings {
  margin-top: 20px;
}

#product-mappings-table .selected {
	background-color: @ninja-light;
	border-radius: 0;
}

.psa-modal {
	.modal-body{
		padding: 25px 10px;
		.form-group:not(:last-child) {
			margin-bottom: 20px;
		}
		.form-group:last-child {
			margin-bottom: 0;
		}
		h3, h4, h5 {
			margin-top: 0;
		}
	}
	.panel-bordered {
	  .ReactVirtualized__Table__Grid {
	    border-radius: 4px;
	    border: 1px solid @ninja-light;
	    background-color: @white;
	  }
	}
}

#psa-account-mapping-table {
  .ReactVirtualized__Table__headerTruncatedText {
    display: flex;
  }
}
