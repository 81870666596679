.device-status {
  font-weight: 600;
  color: @ninja-light;
  &.success {
    color: @green;
  }
  &.warning {
    color: @yellow;
  }
  &.danger {
    color: @red;
  }
}

.device-status-bar {
  border-radius: 3px;
  height: 3px;
  width: 100%;
  background-color: @ninja-light;
  &.success {
    background-color: @green;
  }
  &.warning {
    background-color: @yellow;
  }
  &.danger {
    background-color: @red;
  }
}

#device-overview{
    .col-xs-9{
        .col-xs-3{
            .list-group-item-text {
                max-width: 600px;
                font-size: 11px;
              }
        }
        .col-xs-9{
            .list-group-item-text {
                max-width: 600px;
                font-size: 13px;
              }
        }
    }
}

.multi-chart-period-select {
    margin-top: 20px;
}

#device-details-content {
  padding-right: 15px;
  overflow-x: hidden;
    h2.section-title{
        color: black;
            margin-top: 40px;

            &.chart-title {
                margin-bottom: 20px;
            }
    }

    .hardware-inventory-panel{
        margin-bottom: 70px;
    }

    .event-log-panel{
        .section-title{
            margin-bottom: 20px;
        }
    }

    .event-log-panel, .anti-virus-panel, .windows-patches-panel{
        header h4{
            padding-left: 2px;
            color: black;
            font-size: 1.1em;
            font-weight: 400;
        }
    }

    .list-group-item-text {
        font-size: 11px;
        white-space: normal
      }
}

#device-settings {
    overflow-x: hidden;

    h4 {
        font-weight: 500;

        span.btn-link {
            padding-right: 10px;
        }
    }

    .disable {
        pointer-events: none;
        color: @ninja-light;
    }

    .device-settings-container {
        height: 100%;
        min-height: 100%;
        content: '';
        clear: both;
        display: table !important;
        width: 100%;
    }

    .left-panel {
        padding-bottom: 10px;
        position: relative;
        background-color: #ffffff;
        height: 100%;
        overflow-y: auto;

        .frame {
            padding: 15px 10px 15px 10px;
        }

        h4{
            color: @gray-dark;
            display: inline;
            font-size: 13px;
        }

        .pull-right.disabled{
            color: @gray;
            padding-right: 10px;
        }

        .policy-settings {
            .row{
                padding: 3px 0;

                small {
                    color: @gray-dark;
                }
            }
        }

        .overrides {

            padding-top: 15px;

            .content {

                .override-header {
                    margin-top: 10px;

                    .link {
                        margin-top: 0px;
                        font-weight: 500;
                        padding-right: 10px;
                    }
                }

                .override-section {
                        padding-top: 5px;

                    .override-type {
                        display: block;
                        font-weight: 600;
                    }

                    .patch {
                        display: block;
                        text-decoration: none;
                        &.strikethrough {
                            text-decoration: line-through;
                            color: @ninja-medium;
                        }
                    }
                }
            }
        }
    }

    .right-panel {
        position: relative;
        padding: 20px 20px 25px;
        height: 100%;
        overflow-y: auto;
        background-color: @gray-lightest;
        border-left: 1px solid @ninja-light;

        .device-applications-header {
            color: @gray-dark;
            font-size: 1.2em;

            .frame {
                padding: 25px 5px 25px 5px;
            }
        }

        div.application {

            div.header {
                margin-top: 10px;

                &.needs-bottom-margin {
                    margin-bottom: 40px;
                }

                .heading {
                    color: black;
                    font-weight: 500;
                    font-size: 1.2em;
                }

                .section-heading {
                    color: black;
                    margin-left: 5px;
                    font-size: 1.2em;

                    .fas {
                        margin-left: 5px;
                        margin-right: 10px;
                        font-size: 1.2em;
                    }
                }

                .application-logo:not(.storage-craft-logo) {
                    width: 50px;
                    height: 50px;
                    margin-top: -5px;
                    margin-right: -10px;
                }

                .storage-craft-logo {
                    max-width: 80px;
                    margin-top: -25px;
                    margin-right: -20px;
                    top: -10px;
                    right: -15px;
                }

                .lockhart-logo {
                    width: 50px;
                    height: 50px;
                    margin-top: -5px;
                    margin-top: -15px;
                    color: @ninja-blue-saturated;
                }
            }

            div.list {
                max-height: 150px;
                overflow-y: auto;

                ul {
                    list-style: none;
                }
            }

            &.cloudberry {
                background-color: white;
                border: 1px solid @ninja-light;
                padding: 15px 30px 5px 30px;

            }

            &.storage-craft {
                background-color: white;
                border: 1px solid @ninja-light;
                padding: 15px 30px 5px 30px;

                .sub-application {
                    color: @gray-dark;
                    margin-bottom: 20px;
                    border: 1px solid @ninja-light;
                    overflow-y: auto;
                    overflow-x: hidden;

                    .frame {
                        padding: 15px 30px 15px 20px;
                    }

                    .sub-application-logo {
                        width: 48px;
                        height: 48px;
                    }

                    div.header {
                        padding: 10px 5px 5px 10px;
                        margin-bottom: 10px;

                        .pull-right {
                            margin-top: 0px;
                        }
                    }

                    .wait-message {
                        font-size: 0.8em;
                    }

                    .jobs-header {
                        font-weight: 600;
                    }
                }
            }
        }

        .device-teamviewer {
            background-color: #FFFFFF;
            margin-top: 15px;

            .frame {
                border: 1px solid @ninja-light;
                padding: 15px 30px 15px 20px;
            }

            .temviewer-content {
                padding-top: 20px;
            }
        }
    }
}

#device-info-lists {
  flex: 1;
  display: flex;
  flex-direction: column;
  > header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    flex-wrap: wrap;
    .tz {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: @ninja-medium;
      padding-bottom: 2px;
    }
    > nav {
      display: flex;
      > button {
        display: block;
        color: @ninja-medium;
        font-size: 13px;
        line-height: 13px;
        font-weight: 600;
        padding: 7px 10px 7px 5px;
        &.active {
          color: @ninja-black;
          cursor: default;
        }
        &:not(.active):hover {
          color: @ninja-dark;
        }
      }
    }
  }
  .note-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    overflow: auto;
    > button {
      text-align: left;
    }
    p {
      padding: 0;
      margin: 0;
      &.note-text {
        white-space: pre-wrap;
        padding: 5px 0;
        word-break: break-word;
      }
    }
    .note-info {
      font-size: 11px;
      color: @ninja-medium;
    }
    .note-actions {
      a, button {
        padding: 5px;
        &:first-child {
          padding-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
}

#device-note-input {
  display: flex;
  textarea {
    flex: 1;
    height: 300px;
    resize: none;
    outline: none;
    border: 1px solid @ninja-light;
    border-radius: 3px;
  }
}
