#u2f-token-modal {
  .token-item{
    display: flex;
    justify-content: space-between;
    padding: 5px; 
    border-bottom: 1px solid @ninja-medium;

    &:last-child {
      border-bottom: 0;
    }
    
    a, button {
      display: none;
      margin-right: 5px;
    }

    &:hover {
      a, button {
        display: inline-block;
      }
    }
  }

  .u2f-header {
    display: flex;
    align-items: center;
  }
}
