.menu-type-description {
  text-align: left;
  padding: 5px;
  color: #808080;
}

#all-customers .radio {
  margin: 15px;
}

#selected-customers {
  margin-left: 10px;
  overflow-x: hidden !important;
  flex-wrap: wrap;
  display: flex;
}

.customer-select {
  display: flex;
  flex: 0 1 50%;
  min-width: 0;
}

.customer-name {
  flex: 0 0 80%;
}

.customer-checkbox {
  flex: 0 0 20%;
}

.customer-name:hover {
  white-space: normal !important;
  overflow-wrap: break-word;
}

#branding-tab {
  .action-links {
    padding-top: 15px;
  }

  #action-deploy {
    padding-top: 45px;
  }

  .branding-organizations {
    padding-top: 30px;
  }

  #specific-organizations {
    margin-top: 20px;
  }
}

.system-tray-editor {
  hr.menu-item-separator {
    margin: 0;
    width: 125px;
    border-top: 1px solid black;
  }

  .rst__rowWrapper {
    padding: 1px
  }

  .menu-item-icon {
    margin-right: 5px;
  }
}

#branding-editor {
  .ninja-header {
    img {
      height: 24px;
      margin-top: 5px;
    }
  }

  .ninja-editor{
    background-color: #FFFFFF;
  }

  .general-div-input {
    margin: -5px 0 0 20px;

    input {
      width: 300px;
    }
  }

  .hostname-div-input {
    margin-left: 55px;

    input {
      text-align: right;
      width: 300px;
    }
  }

  .hostname-domain {
    font-size: 14px;
    margin: 8px 0 0 20px;
  }

  .phone-input-container {
    margin: -5px 0 0 20px;
    .PhoneInput {
      padding: 6px 12px;
      height: 34px;
      width: 300px;
    }
    .PhoneInputCountryIcon {
      height: 20px;
      width: 20px;
    }
  }

  .th-topic-style {
    text-align: center;
  }

  .th-action-style {
    text-align: center;
  }

  .topic-action-table-container {
    max-height: 350px;
    overflow-y: scroll;
  }

  .topic-action-table {
    margin-left: 15px;
    width: 98%;
  }

  .topic-table-td {
    text-align: center;
    white-space: normal !important;
    word-wrap: break-word;
  }

  .remove-topic-icon {
    color: red;
  }

  .text-center {
    text-align: center;
  }

  .with-margin-right {
    margin-right: 2px;
  }

  .theme-div {
    width: 250px;
  }

  .color-preview {
    border: 1px solid gray;
    margin: auto;
    margin-bottom: 10px;
    height: 40px;
    width: 70px;
  }

  .tooltip-branding {
    margin: 4px 0 0 5px;
  }
}

#website-branding-config {
  .website-header-container {
    border-bottom: 1px solid gray;

    .website-header {
      margin: 10px 10px 0 10px;
    }
  }

  .website-container {
    margin: 10px;

    .website-settings-container {
      border-right: 1px solid gray;
      margin: 10px 0 0 5px;
      width: 40%;

      a {
        text-decoration: underline;
        word-wrap: break-word;
      }

      .general-label-item {
        color: #999;
      }
    }

    .website-images-container {
      width: 60%;

      .website-images-items-container{
        margin: 10px;

        .website-images-items {
          text-align: center;

          .website-config-image {
            border: 1px solid gray;
            height: 100px;
            line-height: 40px;
            text-align: center;
            vertical-align: middle;
            width: 100px;
          }

          .website-config-theme {
            border: 1px solid gray;
            height: 30px;
            margin-left: 30px;
            width: 50px;
          }

          .theme-label-item {
            font-size: 10px;
            margin: 0 0 3px 0;
          }

          .theme-text-color-container {
            margin-top: 12px;
          }

          .image-label-item {
            color: #999;
          }
        }
      }
    }
  }

  .label-enabled {
    color: rgb(112, 173, 71);
  }

  .label-disabled {
    color: red;
  }
}

.help-topics {
  max-height: 400px;
  overflow: auto;
}
