#widget-grid.cloud-monitor-dashboard{
	
	.http-headers{
		.dl-horizontal{
			dt{
				width: 130px;
			}

			dd{
				margin-left: 150px;
				white-space: normal;
				word-break: break-all;
				text-align: left;
			}
		}
	}

	table{
		td{
			white-space: normal;
		}

		.dl-horizontal{
			dt{
				width: 40px;
			}

			dd{
				margin-left: 60px;
				white-space: normal;
				word-break: break-all;
				text-align: left;
			}
		}

		&.email-monitor-table{
			.dl-horizontal{
				> div{
					margin-bottom: 30px;

					strong{
						font-size: 1.1em;
						line-height: 2em;
					}

					dt{
						width: 120px;
					}

					dd{
						padding-left: 100px;
					}
				}
			}
		}
	}
}