.filters-container {
  font-size: 14px;
  line-height: 1.1;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * {
    margin-bottom: 5px;
  }
  &.active .filter-header {
    color: lightgray;
  }
  &.wrap {
    flex-basis: 100%;
  }
  li {
    margin-right: 15px;
    display: inherit;
    flex-direction: column;
    position: relative;
    &.active .filter-header {
      color: @text-color;
    }
    &.additional-filter-break {
      margin: 0 !important;
      display: flex;
      flex-basis: 100%;
    }
    .filter-header {
      cursor: pointer;
      &.divider-left {
        border-left: 1px solid @border-color;
        padding-left: 10px;
      }
      > .fa-xs {
        position: relative;
        bottom: 1px;
        width: 10px;
        &.fa-circle-xmark {
          color: @ninja-medium;
          margin-left: 5px;
          &:hover {
            color: @ninja-dark;
          }
        }
      }
    }
    .filter-select {
      position: absolute;
      top: calc(~"100% + 4px");
      z-index: 1;
      > div, > input {
        flex: 1;
      }
      .Select.is-open > .Select-control:only-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.left-0 {
        left: 0;
      }
      &.right-0 {
        right: 0;
      }
    }
    .filters {
      display: inline-flex;
      margin-left: 5px;
    }
  }
  &.data-table-filters-container {
    li {
      margin-right: 8px;
    }
  }
  #tooltip-right {
    left: 100%;
    .tooltip-inner {
      white-space: pre;
      text-align: left;
    }
  }
}

.conjunction {
  margin: 0 4px;
  &:last-of-type {
    display: none;
  }
}

.group-description {
  .text-wrap;
  line-height: 1;
  font-size: 14px;
  margin-bottom: 15px;
}

.date-picker-selector {
  background-color: #FFFFFF !important;
}
