.configuration {
  flex: 1;
  display: flex;
  flex-direction: column;
  #widget-grid {
    flex: 1;
    display: flex;
    > div:not(#tab-header) {
      flex: 1;
      display: flex;
      flex-direction: column;
      .tab-actions-container ~ .horizontal-tiles {
        display: flex;
        flex-direction: column;
      }
      .horizontal-tiles .message {
        min-height: 15px;
      }
      .horizontal-tiles:not(.ReactVirtualized__Table) {
        flex: 1;
        overflow-y: auto;
      }
    }
  }

  .panel:not(.panel-danger) .panel-heading {
    user-select: none;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    color: @ninja-medium;
  }

  .panel-body.read-only {
    display: flex;

    .panel-section {
      display: flex;
      flex-direction: row;

      .top-section {
        &.panel-right, &.panel-left {
          span {
            margin-bottom: 10px;
          }
        }

        &.panel-left {
          color: @ninja-dark;
          font-weight: bold;
          min-width: 156px;
        } 

        &.disabled {
          span {
            margin: 0;
          }
        }
      }
    }

    .panel-left,
    .panel-right {
      display: flex;
      flex-direction: column;
      > span {
        min-height: 18px;
      }
    }
    .panel-left {
      color: @ninja-medium;
    }
    .panel-right {
      flex: 1;
      margin-left: 30px;
    }
  }

  .general-config {
    width: 100%;

    .panel-heading {
      border: none;
    }
    .list-group {
      overflow-y: hidden !important;
      .horizontal-tile {
        .list-group-item;
        &.flex-tile {
          display: flex;
        }
        border-left: 0;
        border-right: 0;
        .tile-content {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .report-settings {
    .tile-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: -1px;
      padding: 10px 15px;
      position: relative;
      border-left: 0;
      border-right: 0;
      .action-links {
        display: flex;
        align-items: center;
      }
    }
    img {
      max-height: 50px;
    }
  }

  .ticket-templates .horizontal-tile h4 {
    margin-bottom: 2px;
  }

  .psa-list > .horizontal-tile {
    padding: 0;
    > a, span {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 110px;
      padding: 15px;
      img {
        max-height: 75px;
        max-width: 300px;
      }
    }
  }

  .vendor-logo {
    display: flex;
    justify-content: center;
    padding: 15px;
    margin-bottom: 15px;
    > img {
      max-height: 55px;
    }
  }

  .panel h4 {
    font-weight: normal;
    font-size: 14px;
    margin: 0;
  }
}

// PSA config
.inline-input-addon {
  display: flex;
  padding: 6px 0;
}

.p-t-6 {
  padding-top: 6px;
}
