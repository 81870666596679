#policy-editor-modal {
  .condition-name,
  .scheduled-actions-name {
    display: flex;
    flex-direction: column;
    flex: .48;
    h4 {
      margin: 0 0 5px;
    }
  }

  .unset-min-height {
    min-height: unset;
  }

  .activity-name {
    flex: .5;
    h4 {
      margin: 0;
    }
  }

  .inheritance-status {
    flex: .1;
    display: flex;
    justify-content: flex-end;
    div, span {
      flex: 1;
    }
    margin: 0 0 0 5px;
    &.empty {
      visibility: hidden;
    }
    &.no-margin {
      margin: 0;
    }
  }

  .read-only .inheritance-status {
    pointer-events: none !important;
  }

  .col-container .row {
    .col-2 {
      flex: .8;
    }
    &.patch-settings {
      flex: 1 0 auto;
      max-height: 160px;
    }
    &.installation {
      min-height: 94px;
    }
    &.security-update-approvals {
      min-height: 132px;
    }
    &.advanced-scan-options {
      min-height: 236px;
    }
    &.approvals {
      min-height: 216px;
    }
    &.active-protection {
      min-height: 112px;
    }
    &.misc {
      min-height: 84px;
    }
    &.resolve {
      min-height: 148px;
    }
    &.syslog-facility {
      min-height: 756px;
    }
    &.syslog-severity {
      min-height: 276px;
    }
    &.traffic-settings {
      min-height: 180px;
    }
    &.traffic-flow {
      min-height: 275px;
    }
  }

  .tab-pane {
    &#network-performance,
    &#configuration-backup,
    &#syslog,
    &#traffic-analysis,
    &#snmp-monitoring {
      .content-container {
        flex: 1;
        width: 100%;
        max-width: unset;
      }
    }
    .spinner-label {
      flex: .6 !important;
      display: flex;
      align-items: center;
      font-weight: 500;
    }
    .activities h4 {
      font-weight: 400;
    }
    &#syslog {
      .col-1 {
        max-width: 200px;
      }
    }
    &#antivirus-tab {
      .col-1 {
        max-width: 275px;
      }
    }
    &#software-patch-management-tab {
      overflow-y: hidden;
      #software-patch-management {
        overflow-y: inherit;
        #SoftwarePatchManagementTabs {
          .nav-tabs {
            li:first-child > a {
              border-left: 0;
            }

            li.active > a {
              color: @ninja-medium;
            }

            li#add-products-link {
              padding: 10px;
            }
          }

          .tab-pane {
            display: none
          }

          .tab-pane.active {
            display: flex;
          }

          .tab-content {
            height: 100%;
          }
        }

        .modal-body {
          padding: 15px;
          text-align: left;
        }

        .icheckbox_square-grey {
          margin-right: 10px;
        }

        .list-toolbar {
          padding: 5px 0;
        }

        .vendor {
          padding: 7px 0 0 0 ;
          min-height: 45px;

          .select-vendor {
            float: left;
            margin: 5px 0;
          }

          .vendor-logo {
            float: left;
            margin-right: 10px
          }
        }

        #software-patch-management-products {
          margin-bottom: 10px;
          
          .toolbar-header {
            .col-xs-10 {
              padding-right: 0;
            }
          }
          .header {
            padding: 10px 37px 0 0;
            min-height: inherit;
            font-weight: bold;

            @media (max-width: 1250px) {
              padding: 10px 10px 0 0;
            }

            >div.col-xs-8+div.col-xs-1 {
              padding: 0;
            }
          }
          .inheritance-status {
            margin: 3px auto 0 auto;
          }
        }

        div.software-patch-management-product-items {
          padding: 0 35px;
          margin: 0;
          min-height: inherit;

          ul.software-patch-management-product-items {
            padding: 0;
            list-style: none;
            width: 100%;

            .software-patch-management-product-item {
              height: 27px;

              .col-xs-8 {
                padding-right: 0;
              }

              >div.col-xs-8+div.col-xs-1.capitalize {
                padding: 0;
              }
            }
          }

          @media (max-width: 1250px) {
            padding: 0 0 0 10px;
          }
        }

        .add-products-list {
          height: 400px;
          overflow-y: auto;

          .panel {
            margin: 0 20px;
          }
        }

        .horizontal-tile {
          padding: 0;
        }
      }
    }


  }
}

#policy-editor-backup-modal {
  .tab-pane {
    text-align: left;
  }
}

#policy-editor-action-modal{
    .progress{
        margin-bottom:0px;
    }

    .input-file
    {
        height: 30px;

        .btn{
            position: absolute;
            float: none;
            font-size: 13px;
        }

        &:not(.no-text-input) .btn{
            top: 6px;
            right: 24px;
            height: 22px;
            padding: 0 14px;
        }

        .btn{

            input{
                position: absolute;
                top: 0;
                left: 0;
                padding: 0;
                cursor: pointer;
                opacity: 0;
                width: 80px;
                height: 20px;
            }
        }
    }

    .application-upload{

        height: 34px !important;

        ins.iCheck-helper{
            width: 22px !important;
        }

        .no-text-input .btn{
            margin-left: 10px;
            top: 3px;

            input{
                width: 72px;
                height: 32px;
            }
        }

        .bar-holder{
            position: relative;
            top: -20px;
            left: 120px;
            width: 60%;
        }
    }
}

#policy-editor-file-extensions-modal{
    .extensions-body
    {
        .extensions-section{
            height: 300px;
            overflow-y: auto;

            .row.form-group{
                margin-bottom: 5px;
            }
        }
    }
}

#policy-editor-scheduled-action-modal, #policy-editor-condition-modal{
    .add-action{
        padding: 8px 0 3px 12px;
        text-align: left;
    }

    .horizontal-tile{
        height: 35px;

        .btn-link{
            margin-top: -10px;
            visibility: hidden;
        }

        &:hover{
            .btn-link{
                visibility: visible;
            }
        }
    }

    .modal.inmodal & {
      .modal-body{
        &.tall-modal{
          max-height: calc(100vh - 180px);
        }
      }
    }
}

#schedule-duration-hours {
  margin-left: 0px;
  width: 50px;
  display: inline;
}

#schedule-duration-minutes {
  width: 50px;
  display: inline;
  margin-left: 10px;
}

.editor-install-text-wrapper{
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #E1E2E4;
  border-radius: 3px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s, -webkit-box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size: 14px;
  font-family: inherit;
  margin: 0;
  font: inherit;
  cursor: text;
  -webkit-appearance: textfield;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-rendering: auto;
  text-align: start;
}

.install-fileupload-container {
  display: flex;
  align-items: center;

  .install-fileupload-wrapper {
    flex: 1;
  }
}

.fixed-info-icon {
  position: absolute;
  right: -3px;
  top: 8px;
}

.label-modal-center {
  padding: 6px 12px;
}

.exclusions.form-group {
  margin-bottom: 30px;
}

.exclusions {
  display: flex;
  align-items: center;

  .exception-path-type {
    width: 250px;
    text-align: left;
    align-items: center;
  }

  .exception-warning {
    text-align: left;
    display: block;
    padding: 0 1px;
    font-style: normal;
    font-size: 11px;
    position: absolute;
  }

  .exception-path {
    flex: 1;
    margin-left: 15px;
    text-align: left;
    align-items: center;
    position: relative;

    em {
      position: absolute;
      width: 100%;
    }
  }
}

.purge-quarantine-row {
  min-height: 100px !important;
}

.purge-quarentine-margin-top {
  margin-top: 10px !important;
}

.form-group{
  em {
    text-align: left;
    display: block
  }
}

.patch-overrides-modal {
  width: 1100px;
}
