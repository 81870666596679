#device-tools {
  position: relative;
}

.remote-tools-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  height: 100%;
  width: 100%;

  .remote-tools-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    font-size: 18px;
    color: @ninja-medium;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .ReactVirtualized__Table__headerRow {
    .ReactVirtualized__Table__headerColumn {
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  .remote-tools-table-row:not(.ReactVirtualized__Table__headerRow) {
    display: flex;
    align-items: center;

    .remote-tools-table-cell {
      margin-left: 5px;
      margin-right: 10px;
      align-items: center;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.checked {
      background-color: #ebf4fd !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid @border-color;
    }

    &:hover {
      cursor: pointer;
      background-color: @ninja-white;
    }

    &:active, &:focus {
      outline: none !important;
    }
  }
}

.remote-tools-actions {
  flex: 0 0 250px;
  margin-left: 40px;
  padding: 10px;
}

.remote-tools-container {
  > div {
    display: contents;
    flex: 1;
  }
}
