.react-datetime-wrapper {
  width: 115px;
}

// this is to style `react-datetime` only

.rdtPicker {
  display: none;
  position: absolute;
  width: auto;
  min-width: initial;
  padding: 0px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: none;
  box-shadow: none;
  border: none;

  .rdtDays, .rdtTime, .rdtMonths, .rdtYears{
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid @gray-border-color;
  }
  
  .rdtDays, .rdtMonths, .rdtYears{
    width: 250px;
  }

  .rdtTime{
    width: 150px;
  }

  &:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.1);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 6px;
  }

  &:after {
    border-bottom: 6px solid #FFFFFF;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -4px;
    left: 7px;
  }
}