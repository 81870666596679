#user-editor{

    .select-name{
        min-width: 100px;
    }

	.switch-scroll {
        .text-ellipsis;
        .selected{
            font-weight: 600;
        }
    }
}