.restore-manager-download-modal {
  &.file {
    .modal-body {
      min-height: 160px;
      padding-bottom: 15px;
      text-align: left;
    }
  }

  .modal-body {
    padding-bottom: 15px;
    text-align: left;
  }
}

.run-backup-plan-modal {
  .modal-body {
    min-height: 80px;
    padding-bottom: 15px;
    text-align: left;
  }
}

.backup-manager-restore-modal {
  z-index: 2200;
  min-width: 540px;
  margin: 30px auto;
  width: 600px;

  .modal-body {
    max-height: 100% !important;
    padding-bottom: 15px;
    text-align: left;

    .file-folder-browser-tree {
      height: 300px;
    }
  }
}