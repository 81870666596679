.panel .horizontal-tiles .horizontal-tile.flex-tile {
  padding: 10px 15px;
}

.horizontal-tiles{
	padding-left: 0px;
  margin-bottom: 0px;

	&.bordered{
		border: 1px solid @ninja-medium;
	}

	.header {
    padding: 10px 10px 10px 15px;
    border-bottom: 1px solid @ninja-medium;

    h3 {
        display: inline-block;
        font-size: 1.2em;
        font-weight: 500;
    }
  }

  &:focus{
    outline: none;
  }

	.horizontal-tile{
		width: 100%;
		display: inline-block;
		margin: 0px;
		padding: 7px;
		padding-right: 10px;
    border: 0;

    &:not(:last-child) {
      border-bottom: 1px solid @ninja-light;
    }

    &.narrow {
      padding: 3px
    }

    &:focus{
      outline: none;
    }

		&:not(.no-hover):hover{
			cursor: pointer;
		}

		.icon{
			font-size: 1.5em;

			img{
				margin-left: -5px;
				max-width: 36px;
			}

			// Order of classes is very important
			// danger > warning > info > success
			&.success{
				color: @state-success-border;
			}

			&.info{
				color: @brand-info;
			}

			&.warning{
				color: @brand-warning;
			}

			&.danger{
				color: @state-danger-border;
			}
		}

		&.actionable{

            // Some users report this element in chrome's desktop browser, it prevents
            // them from interacting with the UI. (from bootstrap)
            .dropdown-backdrop {
                display: none;
            }

            &.removeable {
                &.remove {
                    -webkit-animation: hideOut 0.5s forwards;
                    -moz-animation: hideOut 0.5s forwards;
                    -o-animation: hideOut 0.5s forwards;
                    animation: hideOut 0.5s forwards;
                }

                @keyframes fade {
                    0%, 100% { opacity: 1 }
                    50% { opacity: 0 }
                }

                &.fade {
                    -webkit-animation: fade 1s forwards;
                    -moz-animation: fade 1s forwards;
                    -o-animation: fade 1s forwards;
                    animation: fade 1s forwards;
                }
            }

			.action-buttons{
				opacity: 1;
			}

			.description{
				font-size: 0.9em;
			}

			.description, .message{
				max-width: 90%;
			}

			&:hover {
				background: @ninja-white;

				.dropdown-button{
					opacity: 1;
				}
			}
		}

		&:not(.actionable) {
			&:not(.no-hover) {
        &:not(.ReactVirtualized__Table__headerRow) {
          &:hover, &.active:hover, &.selected {
            background: @ninja-white;
            .action-buttons:not(.dropdown-button) {
              opacity: 1;
            }
          }
        }
			}
		}

		span.action-buttons{
			opacity: 0;
			color: @ninja-blue-saturated;
			background: none;
			float: right;
      margin-top: -28px !important;
			padding-right: 20px;
			font-size: 1.4em;
			transition: 0.4s opacity;
			position: relative;

			i{
				margin-left: 5px;
			}

			:hover{
				color: @gray-dark;
			}

      .action-text {
        font-size: 0.6em;
      }
		}

		hgroup{
			margin-left: 5px;

      div:first-child svg {
        color: @ninja-dark;
      }

			h4{
        &:not(.text-muted) {
          color: @ninja-dark;
        }
				font-weight: 600;
				margin-top: 0;
        margin-bottom: 3px;

				.tile-title-icon{
					padding-left: 5px;
					font-size: 1em;
				}

        ~small,
				small{
          color: @ninja-medium;
					font-size: 10px;
				}

				strong{
					color: black;
				}
			}

			small.description{
				.text-ellipsis;
				color: @ninja-dark;
				font-weight: 400;
        display: block;
        &:not(.m-t-none) {
          margin-top: 5px;
        }
        &.report-customer {
          margin-top: 3px;
        }
        &:hover {
          // TODO: this is messing up some descriptions that don't have fix width parents (ex: patches list)
          white-space: initial;
        }
			}

			small.message{
				.text-ellipsis;
				border: 0;
				padding: 0;
				margin-top: 3px;
				background-color: transparent;
				display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
				display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
				display: -ms-flexbox;      /* TWEENER - IE 10 */
				display: -webkit-flex;     /* NEW - Chrome */
				display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
				color: @ninja-medium;

                a:hover {
                    text-decoration: underline;
                }
			}

			span.status-icons{
				position: static;

				.count-bubble {
					opacity: 0;
			    position: absolute;
			    margin-top: -22px;
			    margin-left: -12px;
			    transform: translate(-50%, -50%);
				}

				// Popover's render as a child of status-icons
				// Thus we need the direct child selector here
				> i {

					margin-left: 7px;

					// This is critical for status-icon hovers to work properly on Chrome
					// It took a couple days of scratching our heads to find this solution...
					&.fa{
					    -webkit-transform: none; /* Safari and Chrome */
					       -moz-transform: none; /* Firefox */
					        -ms-transform: none; /* IE 9 */
					         -o-transform: none; /* Opera */
					            transform: none;
					}

					.count-bubble {
						font-weight: 700;
					}

					&:hover{
						color: @gray-dark;

						.count-bubble{
							opacity: 1;

							&:hover{
								opacity: 0;
							}
						}
					}

					&.fa-spinner:hover + .count-bubble {
						transition: opacity 0.3s;
						opacity: 1;
						background-color: lighten(@brand-primary, 20%);
					}

					&.info{
						color: lighten(@brand-primary, 10%);

						&:hover{
							color: lighten(@brand-primary, 20%);
						}
					}

					&.warning{
						color: @brand-warning;

						.count-bubble{
							background-color: @brand-warning;
						}

						&:hover{
							color: lighten(@brand-warning, 10%);

							.count-bubble{
								background-color: lighten(@brand-warning, 10%);
							}
						}

					}

					&.danger{
						color: @brand-danger;

						.count-bubble{
							background-color: @brand-danger;
						}

						&:hover{
							color: lighten(@brand-danger, 5%);

							.count-bubble{
								background-color: lighten(@brand-danger, 5%);
							}
						}
					}
				}
			}

			span.action-buttons{
				font-size: 1.8em;

				&.dropdown-button{
					opacity: 0;
					top: -20px;
					background: none;
					box-shadow: none;

					a:focus, i:focus{
						outline: none;
					}
				}
			}

			.dropdown-menu{
				left: -155px;
                top: 18px;
			}
		}

		&.stat-board{
			.stat-header{
				font-size: 0.8em;
			}
			.stat-value{
				font-size: 1.1em;
			}
		}

		&.hidden-info{
			display: none;
		}

		&.fat-tile{
			small.description{
				margin-top: 0px;
			}
		}
	}

	.no-results-message{
		line-height: 3em;
		text-align: center;
	}

  .horizontal-tile.flex-tile {
    display: flex;
    align-items: center;
    padding: 5px 0;
    > div {
      flex: 1;
      display: inherit;
      justify-content: space-between;
      align-items: center;
      min-height: 41px;
      > hgroup {
        display: inherit;
        flex-direction: column;
        justify-content: center;
      }
      h4 {
        margin-bottom: 0;
      }
    }

    &.large-tile > div {
      min-height: 52px;
    }

    &:not(.no-hover) .action-links {
      opacity: 0.01;
      transition: opacity .2s;
    }

    &:not(.no-hover):hover .action-links {
      opacity: 1;
    }

    .action-links {
      a {
        margin-left: 10px;
			}
			button {
        margin-left: 10px;
      }

			&.collapse-vertical {
				display: flex;
				@media (max-width: 1600px) {
					flex-direction: column;
					align-items: flex-start;
				}
			}
    }
  }
}

.count-bubble {
  z-index: 10;
  padding: 4px 7px 4px 7px;
  background-color: @brand-info;
  color: white;
  min-height: 16px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 1px 1px 3px @gray-light;
  white-space: nowrap;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 500;
  transition: opacity .3s;

  &.info{
    background-color: lighten(@brand-primary, 10%);
  }

  &.warning{
    background-color: lighten(@brand-warning, 10%);
  }

  &.danger{
    background-color: lighten(@brand-danger, 5%);
  }

  &.count-bubble-appear {
    opacity: 0.01;

    &.count-bubble-appear-active {
      opacity: 1;
      transition: opacity .3s ease-in;
    }
  }
}

.horizontal-tiles {
  .horizontal-tile {
    hgroup {
      small.no-ellipsis {
        white-space: normal;
        overflow: auto;
      }
    }
  }
}

svg {
  &.success{
    color: @state-success-border !important;
  }

  &.info{
    color: @ninja-blue-saturated !important;
  }

  &.warning{
    color: @brand-warning !important;
  }

  &.danger{
    color: @state-danger-border !important;
  }
}
