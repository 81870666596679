#policy-backup-plans-section {
  flex: 1;
  width: 100%;
  overflow: visible;
  padding-left: 30px;
  display: flex;

  .backup-plans-container {
    flex: 1;
    height: 100%;
    display: flex;

    .list-group-item, .horizontal-tile {
      border: none;
      padding: 0;
    }

    .ReactVirtualized__Table__headerRow {
      overflow: visible !important;
    }

    .backup-plans-table-row:not(.ReactVirtualized__Table__headerRow) {
      align-items: flex-start;
      padding: 10px 0px;

      &:not(:last-child) {
        border-bottom: 1px solid @ninja-light;
      }

      .backup-plan-row-actions {
        display: none;
      }

      &:hover {
        cursor: pointer;
        background-color: @ninja-white;

        .backup-plan-row-actions {
          display: block;
        }
      }
    }

    .backup-plans-table-header {
      border: none !important;
      font-size: 13px;

      > span {
        color: @ninja-medium;
      }
    }

    .backup-plans-table-cell {
      padding-left: 5px;
      height: 100%;
      position: relative;

      h4 {
        margin: 0;

        span {
          white-space: nowrap;
          display: inline-block;
          overflow: hidden;
          line-height: 18px;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }

      .backup-plan-folders {
        color: @ninja-light;
        overflow-y: scroll;
        height: 100% - 25px;
        margin-top: 2px;
        right: -16px;
        overflow-x: hidden;
        width: 100%;
        position: absolute;
        padding-right: 16px;

        > div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            color: @ninja-medium;
          }
        }
      }
    }
  }
}

.backup-plan-folder {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .folder-icon {
    font-size: 18px;
    color: @ninja-yellow;
  }

  .action-links {
    display: none;
  }

  &:hover {
    background-color: @ninja-white;

    .action-links {
      display: block;
    }
  }
}

.common-folder {
  white-space: nowrap;
  width: 50%;
  padding: 3px 0px;

  &.m-l {
    margin-left: 25px;
  }
}

.cb-folders-paths-container {
  margin: 0;
  overflow-x: hidden;
}

.folders-paths-container {
  margin: 0;
  height: 370px;
  padding: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.backup-plan-modal {
  width: 800px;
}
