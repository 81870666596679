#monitor-wizard-modal {

    .content-container{
        height: calc(~"100vh - 211px") !important;
    }

    .rotated {
        &.rotate {
            -webkit-animation: flipOutX 0.8s forwards;
            -moz-animation: flipOutX 0.8s forwards;
            -o-animation: flipOutX 0.8s forwards;
            animation: flipOutX 0.8s forwards;
        }
    }

    .btn-default {
        color: @ninja-dark;
        background-color: #fff;
        border-color: @ninja-medium;

        &:hover, &:focus, &:active, &.active, .open .dropdown-toggle.btn-default {
            background-color: @ninja-light;
            border-color: @ninja-medium;
            color: @ninja-dark;
        }
    }

    .padding-top{
        padding-top: 55px;
    }

    .full-width{
        width: 100%;
    }

    .center-align{
        text-align: center;
    }

    .verify-sub-heading{
        text-decoration: underline;
    }

    .state-error textarea{
        background: @ninja-light;
        border-color: @ninja-red;
    }

    .http {

        &:hover {
            .items span i {
                transition: .2s all;
                opacity: 1;
            }
        }

        span {
            i {
                font-size: 140%;
                line-height: 30px;
                margin-left: 5px;
                opacity: .2;
                transition: .25s all;
            }

            &:hover {
                color: @navy;
                text-decoration: none;
                i{
                    opacity: 1;
                }
            }
        }

        &:last-child span i {
            display: none;
        }
    }

    header{

		padding-top: 6px;

		&:not(.has-steps){
			margin-bottom: 50px;
		}

		.menu-buttons{
			margin-top: 15px;
		}

		.steps{
			margin-top: 30px;

			ul{
                padding-left: 0;
				text-align: center;
				list-style-type: none;

				li{
					display: inline-block;

					.progress{
						height: 0.8em;
						margin: 0 5px;
						width: 125px;

						.progress-bar{
							width: 100%;
						}
					}

					.step-label{
						display: inline;
						line-height: 2em;
						font-size: 1.1em;

						&.active{
							color: black;
							font-weight: 600;
						}
					}
				}
			}
		}
	}

    .confirm-page{
        .form-group{
            margin-bottom: 0px;

            .control-value{
                text-align: left;
                font-weight: 500;
            }
        }
    }

    .form-group{
        .icheckbox_square-green{
            margin-top: 4px;
        }
    }

    .iCheck-helper {
        width: inherit !important;
        margin-left: 15px !important;
    }
}
