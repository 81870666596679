#script-parameter-modal {
  .col-sm-8 {
    text-align: left;
  }

  .help-block {
    margin: 0;
  }

  .modal-body {
    min-height: 250px;
  }
}

.scripts-table {
  div {
    color: @ninja-dark;
    text-align: left;

    .list-group-item-data {
      height: 100%;

      b {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
      }
  
      .fa-circle-exclamation {
        color: @ninja-red;
      }
    }
  }
}

.draggable-scripts-table {
  color: @ninja-dark;
}

.ace_tooltip {
  max-width: 300px !important;
  word-wrap: break-word !important;
  white-space: normal !important;
  background-color: @script-editor-form-grey;
  color: @ninja-medium;
}
