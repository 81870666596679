.stat-board{
  margin-top: 20px;
  margin-bottom: 10px;

  .stat-board-horizontal{
    margin-top: 10px;

    small.stat-board-heading{
    font-size: 1em;
    color: black;
    font-weight: 600;
    margin-left: 4px;
    line-height: 2.5em;
    }
  }

  &:not(.overview-stat-board) h3{
    margin-bottom: 7px;
  }

  &.overview-stat-board{
    margin: 0;

    .mini-flots{
      padding: 0 0 0 10px;
    }

    .vendor-icons{
      display: inline-block;
      float: right;

      img{
      width: 64px;
      height: 64px;
      margin-bottom: 15px;
      object-fit: contain;
      }
    }
  }

  h3{
    font-size: 14px;
    font-weight: 500;
    margin-top: 0;
    overflow-wrap: break-word;

    small{
      color: inherit;
      font-size: 11px;
      line-height: 2.1em;
    }
  }

  h4{
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
    color: @gray-light;
    margin-bottom: 0px;
  }
  
  .overview-wrapper {
    padding-left: 28px;
    padding-right: 28px;
  }

  .overview-info{
    h3{
      font-size: 16px;
      margin-bottom: 5px;
    }

    dt{
      padding-left: 15px;
      white-space: normal;
    }

    dd{
      margin-left: 190px;
    }
  }

  .list-group,.list-group-item{
    border: 0;
    padding-left: 0; padding-right: 0;
  }
  .list-group-item{
    //cursor: pointer;

    &:first-child{
      padding-top: 0;
    }

    .clearfix {
      &:before,
      &:after {
        content: " "; // 1
        display: table; // 2
      }
      &:after {
        clear: both;
      }
    }

    .list-group-item-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 45px;
    }
    .list-group-item-heading {
      margin: 0;
    }
    .list-group-item-text{
      margin: 0;
      font-size: .9em;
    }

    &:hover{
      // border-color: @table-border-color;
      // border-right: 2px solid @table-border-color;
    }
  }

  .lead{
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    color: black;
  }

  .dl-horizontal dt{
    text-align: left;
    font-weight: normal;
    color: @gray-light;
  }

  .dl-horizontal.dl-small{
    dt {
      width: 70%;
    }
    // &.supersmall{
    // 	dt {width: 50%;}
    // }
    font-size: .8em;

    dd {
      margin-left: 70%;
    }

    &.semi-small {
      dt {
        width: 50%;
      }
      dd {
        margin-left: 50%;
      }
    }

    &.dd-right{
      dd{
        text-align: right;
        white-space: nowrap;
      }
    }
    &.network-stats{
      dt{
        width: 40%;
      }
      dd{
        margin-left: 40%;
      }
    }
  }

  .alert{
    &.alert-network{
      margin-top: 5px;
      margin-bottom: 10px;
    }

    &.flot-success{
      margin-top: 4px;
      margin-bottom: 5px;
    }

    &.flot-success-light{
      margin-top: 4px;
      margin-bottom: 10px;
    }

    padding: 0 5px;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
    border-left-width: 4px;
    border-radius: 0px;

    p{
      color: @black;
    }
  }

  .pack-cols{
    // .massive{
    // 	.font-sm();
    // 	&.pack{
    // 		margin-bottom: 5px;
    // 	}
    // }
  }
}

.disk-stats-main{
  div.col-xs-4{
    padding-left: 26px;
    padding-right: 0px;
    margin-right: 0px;
  }

  div.col-xs-3{
    padding: 0px;
  }
}

.disk-stats{
  div{
    margin-left: 14px;
    &.col-xs-4{
      position: relative;
      left: 8px;
    }
  }
}

.panel-header-stats{
  margin-bottom: 30px;
  white-space: nowrap;
  text-align: center;

  h6{
    font-size: 13px;
    font-weight: normal;
    color: @gray-light;
    margin-bottom: 0px;
  }

  h5{
    font-size: 17px;
    font-weight: 400;
  }

  p{
    font-size: 14px;
    color: @ninja-dark;
  }

  .dl-horizontal{
    font-size: 12px;
    margin-top: 21px;

    dt{
      white-space: nowrap;
    }

    dd{
      .text-ellipsis;
      text-align: left;
      margin-left: 0px;
    }

    &.dl-horizontal-right{
      dt{
        width: auto;
      }
      dd{
        text-align: right;
      }
    }
  }
}
