#scheduled-task-editor {
  .ninja-header {
    img {
      height: 24px;
      margin-top: 5px;
    }
  }

  .ninja-editor{
    background-color: #FFFFFF;

    .header {
      .btn-margin-left {
        margin-left: 5px;
      }
    }
  }

  .scheduled-task-details {
    display: flex;
    flex-flow: row;
    margin: 30px 0 0 30px;
    min-width: 450px;
    overflow-y: auto;

    .input-name-error {
      border-color: red;
    }

    .scheduled-task-scripts {
      margin-left: 0px;
    }
  }

  .scheduled-task-targets {
    margin-left: 30px;

    .ReactVirtualized__Table__Grid {
      max-height: 500px;
      overflow-y: scroll !important;
    }

    .target-row {
      align-items: stretch;

      .target-icon-name {
        display: flex;
        align-items: center;
        > svg {
          margin-left: 5px;
        }
        h4 {
          margin: 0 0 0 5px;
        }
      }
      .target-description {
        margin: 7px 0 0 5px;
      }
    }
  }

  .actions-target-links {
    text-align: right;
  }

  .no-task-created {
    margin: 50px 0 0 50px;
  }
}

.week-day-checkbox-unselected {
  border: 1px solid #d2d2d2;
  color: #c5c5c5;
  font-size: small;
  height: 22px;
  padding-top: 1px;
  text-align: center;
  width: 22px;

  &:hover {
    background-color: gray;
  }
}

.week-day-checkbox-selected {
  background-color: #e6e6e6;
  border: 1px solid #d2d2d2;
  color: black;
  font-size: small;
  height: 22px;
  padding-top: 1px;
  text-align: center;
  width: 22px;

  &:hover {
    background-color: gray;
  }
}

.add-targets-modal {
  .available-targets {
    background-color: @white;
    border: 1px solid @light-grey;
    height: 300px;
    margin-top: 20px;
    text-align: left;
    overflow: scroll;
    padding-top: 10px;

    .target-check-box {
      margin: 0 0 0 10px;
    }

    .target-row {
      align-items: stretch;

      .target-icon-name {
        display: flex;
        align-items: center;
        > svg {
          margin-left: 5px;
        }
        h4 {
          margin: 0 0 0 5px;
        }
      }
      .target-description {
        margin: 7px 0 0 5px;
      }
    }
  }
}

.weekly-schedule {
  display: flex;
  flex-flow: row;

  .weekly-schedule-info {
    margin: 4px 0 0 8px;
  }
}

.task-name {
  > span {
    color: @ninja-medium
  }
}

.configuration-tasks {
  .action-links {
    flex: 0.5;
  }
} 
