.file-selector-container {
  position: relative;
  min-width: 210px;
  height: 34px;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  .label-container {
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    background-color: @ninja-light;
    border-radius: 3px;

    .browse {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 2;
    }

    input {
      background-color: rgba(0, 0, 0, 0);
      height: 100%;
      width: 100%;
      border: none;
      cursor: pointer;
      padding: 0 10px;
    }

    .progress-bar {
      top: 0;
      left: 0;
      height: 100%;
      background-color: @ninja-blue-saturated;
      border-radius: 3px 0px 0px 3px;
      transition: width 500ms ease-in;
      position: absolute;
      font-weight: bold;
      color: @ninja-white;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;

      &.progress-bar-danger {
        background-color: @red;
      }
    }
  }

  button {
    width: 80px;
    height: 100%;
    background-color: @ninja-blue-saturated;
    border-radius: 0px 3px 3px 0px;
    transition: margin-right 400ms ease-in-out;

    &.btn-danger {
      background-color: @red;
    }

    &.slide-out {
      margin-right: -80px;
    }

    &.slide-in {
      margin-right: 0px;
    }
  }
}