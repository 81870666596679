#device-search {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 9px;
  min-width: 1px;

  .ReactVirtualized__Table__headerRow {
    overflow: initial !important;
  }

  .horizontal-tiles .horizontal-tile {
    padding: 10px 0;
  }

  header {
    display: flex;
    justify-content: space-between;
    .group-title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      > *:not(h1) {
        margin-left: 15px;
      }
    }
    h1.page-title {
      line-height: 1.5;
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }
    > ul {
      display: flex;
      margin: 0;
      padding: 0;
      > li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    a, span {
      line-height: 1;
    }
  }
  .device-icon {
    display: flex;
    justify-content: center;
    color: @ninja-dark;
    margin-right: 3px;
    margin-top: 2px;
    margin-bottom: 2px;
    width: 20px;
    font-size: 17px;
  }
  .device-info {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    .device-name {
      display: flex;
      align-items: center;
      > svg:not(.device-icon) {
        margin-left: 5px;
      }
      h4 {
        margin: 0;
      }
    }
    .device-organization {
      margin: 0;
    }
    .device-last-login,
    .device-agent-version,
    .device-sub-type {
      color: @ninja-medium;
    }
    .device-last-login,
    .device-policy,
    .device-sub-type {
      padding-right: 3px;
    }
    .device-policy em {
      padding-left: 3px;
    }
    .m-t-xs:empty {
      margin: 0;
    }
  }

  .device-list-actions {
    display: inline-flex;
    > *:not(:first-child) {
      margin-left: 10px;
    }

    .hover-dropdown {
      padding-bottom: 0;
    }
  }

  .device-right-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .device-timestamp {
      font-size: 10px;
      text-align: right;
    }
    .device-status-icons {
      display: inline-flex;
      justify-content: flex-end;
    }
  }

  .search-results-label {
    color: @ninja-dark;
    font-weight: 400;
  }

  .range-filter-container {
    position: relative;
    .range-filter {
      display: flex;
      flex-direction: column;
      z-index: 5000;
      position: absolute;
      border: 1px solid @ninja-light;
      background-color: #ffffff;
      border-radius: 5px;
      top: 100%;
      min-width: 520px;
      padding: 10px;
      .time-range-filter {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }
      .filter-contains-options {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        .Select {
          flex: .5;
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
        p {
          margin: 0 10px 0 0;
          padding: 12px 0 0 0;
          display: inline-flex;
          align-items: flex-start;
          &.trailing-sentence {
            margin-left: 5px;
          }
        }
      }
      .filter-selection {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        color: @ninja-medium;
        .selection-header {
          flex: 1;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid @ninja-light;
          span {
            padding: 0 10px 5px;
          }
        }
        .selection {
          padding: 10px;
          max-height: 200px;
          overflow-y: auto;
          > li {
            margin: 0;
            &:not(:last-child) {
              padding-bottom: 5px;
              border-bottom: 1px solid @ninja-light;
            }
          }
          .selection-clearable {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 5px;
            > span {
              display: inline-flex;
              align-items: center;
            }
            .actions {
              padding-left: 5px;
              opacity: 0;
            }
            &:hover .actions {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .load-groups {
    width: 500px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    > div {
      display: block;
    }
  }
}

#deviceInfoTooltip {
  .tooltip-inner {
    text-align: left;
    ul {
      padding: 0px;
    }
  }
}

#deviceMaintenanceTooltip {
  .tooltip-inner {
    text-align: left;
    max-width: none;
    ul {
      padding: 0px;
    }
  }
}

.webroot-actions {
  display: flex;
  flex-flow: column;
}

#groups-menu {
  .group-item {
    cursor: pointer;
    padding-left: 10px;
    color: @blue;
    span {
      height: 15px;
    }
  }
}
