// #page-wrapper {
//     position: inherit;
//     margin: 0 0 0 @sidebar-width;
//     min-height: 1200px;
// }

.navbar-static-side {
  z-index: 2001;
  position: absolute;
  width: 180px;
}

.navbar-right {
  margin-right: -25px;
}

.inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.state-error input {
  background: @ninja-white;
  border-color: @ninja-red;
}

.state-error + em {
  display: block;
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: @ninja-red;
}

.not-scrollable {
  overflow: hidden;
}

.auto-scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-wrap {
  word-wrap: break-word;
}

.text-nowrap {
  white-space: nowrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.no-hover {
  cursor: default !important;
}

.no-background {
  background: transparent !important;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.btn-time-picker {
  background-color: white;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: inset 0 -2px 0 rgba(red(@black), green(@black), blue(@black), 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(red(@black), green(@black), blue(@black), 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(red(@black), green(@black), blue(@black), 0.05);
  .fa {
    margin-left: 10px;
  }
}

// Allows tooltips on modal windows
.tooltip {
  z-index: 100001 !important;

  &.tooltip-dl {
    .tooltip-inner {
      color: black;
      background: @gray-lighter;
      border-radius: 10px;
      border: 1px solid @gray;

      .dl-horizontal {
        padding: 10px 0px;

        dt {
          width: 80px;
        }

        dd {
          word-break: break-all;
          text-align: left;
          margin-left: 90px;
          width: 150px;
        }
      }
    }

    .tooltip-arrow {
      border-top-color: @gray;
    }
  }
}

div.popover {
  max-width: 400px;
  z-index: 3000;
}

div.popover.node-actions {
  opacity: 0;
  margin-left: 17px;

  .popover-content {
    padding: 0;
    max-height: 250px;
    overflow-y: auto;
    ul.horizontal-tiles .horizontal-tile .col-xs-11 {
      padding-right: 0px;
    }
  }

  &.fetched {
    opacity: 1;
  }
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.italic-placeholder {
  &::-webkit-input-placeholder {
    font-style: italic;
  }
  &::-moz-placeholder {
    font-style: italic;
  }
  &:-ms-input-placeholder {
    font-style: italic;
  }
  &.single-line-placeholder {
    padding: 8px 8px 9px 7px;
  }
}

.ease-font-weight {
  .form-group {
    .control-label,
    .form-control {
      font-weight: 500;
    }
  }
}

ul.chosen-results li {
  a {
    color: @gray;
  }

  &:hover a {
    color: white;
  }
}

hr.thin {
  margin: 3px 0 15px 0;
}

.bootstrap-timepicker {
  input.form-control {
    width: 87px;
  }
}

.date {
  input.form-control {
    width: 100px;
  }
}

.center-vertically {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.visibility-hidden {
  visibility: hidden;
}

.scrolling-chosen .chosen-container-multi .chosen-choices {
  height: 86px !important;
  overflow-y: auto;
}

#super-admin {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  padding-bottom: 10px;

  span.csv-link {
    padding-left: 7px;
    position: relative;
    top: 22px;
  }

  .form-group {
    width: 88%;

    em {
      text-align: left;
      display: block;
      margin-top: 6px;
      padding: 0 1px;
      font-style: normal;
      font-size: 11px;
      line-height: 15px;
      color: @ninja-red;
    }
  }

  .modal-footer {
    padding: 10px 5px 5px 5px;
  }

  #super-admin-details {
    height: 200px;
    padding-top: 60px;
  }
}

// Copy of fadeInDown - added as a hack to suppport rv-class-fadeInDown (rivets receives arguments lowercased)
.fadeindown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.string-break {
  word-break: break-all;
}

.input-file.team-viewer-change-password {
  line-height: 30px;
}

.display-flex {
  display: flex;
}

.display-flex-important {
  display: flex !important;
}

.hidden {
  display: none;
}

.flex-column {
  flex-direction: column;
}

.flex-full {
  flex: 1;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-direction-column {
  flex-direction: column;
}

.border-radius-none {
  border-radius: 0 !important;
}

.list-toolbar {
  display: flex;
  justify-content: space-between;
  button:first-child {
    padding: 0;
  }
  button {
    font-size: 12px;
  }
  .list-actions {
    display: flex;
    align-items: center;
  }
}

.capitalize {
  text-transform: capitalize;
}

p.force-capitalize {
  margin: 0;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.filter-textbox {
  border-radius: 3px;
  margin: 10px 0 0;
}
.ui-slider {
  z-index: 3500;
}
.ui-slider .ui-slider-handle {
  z-index: 4000;
}

.height-auto {
  height: auto;
}

.float-left {
  float: left;
}

.bold-text {
  font-weight: bold;
}

.extra-bold-text {
  font-weight: 700;
  font-size: 15px;
}

.full-width {
  width: 100%;
}

.pos-rel {
  position: relative;
}

.hover-dropdown,
.nested-hover-dropdown {
  position: relative;
  &:not(.no-padding) {
    padding-bottom: 8px;
  }
  .hover-dropdown-title {
    z-index: 2000;
  }
  .disabled {
    color: @ninja-dark;
  }
  .hover-dropdown-content-container,
  .nested-hover-dropdown-content-container {
    min-width: 250px;
    display: none;
    border: 1px solid @border-color;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    background-color: #ffffff;
    z-index: 1999;
    top: 20px;
    left: -5px;
    &.right-justify {
      left: initial;
      right: -5px;
      .nested-hover-dropdown-content-container {
        left: initial;
        right: 100%;
      }
      &.right-zero {
        right: 0;
      }
      a {
        justify-content: flex-end;
      }
    }
    .hover-dropdown-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      a,
      button {
        font-size: 14px;
        flex: 1;
        display: inline-flex;
        padding: 12px 10px;
        white-space: nowrap;
      }
      > * {
        display: flex;
        min-height: 40px;
        &:hover {
          background-color: @ninja-white;
        }
      }
    }
  }
  .nested-hover-dropdown-content-container {
    border-radius: 0;
    top: -1px;
    left: 100%;
    right: initial;
    max-height: 400px;
    overflow-y: auto;
  }
  &:hover {
    .hover-dropdown-title {
      color: @ninja-dark;
      cursor: default;
    }
    .hover-dropdown-content-container {
      display: flex;
    }
  }
}

.nested-hover-dropdown:hover .nested-hover-dropdown-content-container {
  display: flex;
}

.code-block {
  font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace;
  color: @ninja-blue-saturated;
  font-weight: bold;
  white-space: pre-wrap;
}

.inmodal-table {
  background-color: #ffffff;
  border: 1px solid @ninja-light;
  border-radius: 3px;
}

em.invalid {
  margin-top: 6px;
  padding: 0 1px;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: @ninja-red;
}

em.m-t-none {
  margin-top: 0;
}

.line-height-one {
  line-height: 1;
}

.hover-cursor-color {
  cursor: pointer;
  color: @ninja-blue-saturated;
}

.datepicker {
  .prev,
  .next {
    width: 30px;
    text-align: center;

    &:hover {
      .hover-cursor-color;
    }
  }

  table {
    width: 100%;
    text-align: center;

    .datepicker-switch,
    .dow,
    .day {
      text-align: center;
    }

    .datepicker-switch {
      &:hover {
        .hover-cursor-color;
      }
    }

    .day {
      &:hover {
        .hover-cursor-color;
        background-color: @ninja-white;
      }
    }

    &.table-condensed {
      .month,
      .year,
      .decade,
      .century {
        padding: 3px;

        &:hover {
          cursor: pointer;
          color: @ninja-blue-saturated;
          background-color: @ninja-white;
        }
      }
    }
  }
}

.install-schedule-options-modal {
  .modal-body {
    text-align: left;
    min-height: 180px;
    display: flex;
    flex-direction: column;

    .form-group {
      text-align: left;
    }
  }
}

.inheritance-status {
  text-align: center;
  width: 90px;
  margin-top: 3px;
  font-size: 0.8em;
  font-weight: 600;
  color: @brand-warning;
  border: 1px solid @brand-warning;
  border-radius: 3px;
  line-height: 22px;

  .overridden,
  .revert {
    color: white;
    display: block;
    background: lighten(@brand-warning, 10%);
  }

  .revert {
    display: none;
  }

  &.with-padding {
    .overridden,
    .revert {
      padding: 0 10px;
    }
  }

  &.reversible {
    cursor: pointer;

    &:hover {
      .overridden {
        display: none;
      }

      .revert {
        display: block;
      }
    }
  }

  &.overridden-actions-sm {
    margin-top: 6px;
    width: 30px;
  }
}

.no-overflow-modal {
  .modal-body {
    overflow: unset !important;
    max-height: none !important;
  }
}

.basic-multi-select {
  .select__menu {
    text-align: left;
  }
}

.form-horizontal .control-label {
  text-align: left;
}

.tab-content {
  .form-horizontal .control-label {
    padding-left: 40px;
  }
}

.btn:focus {
  outline: none !important;
}

.modal-form-groups {
  .form-group {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;

    em {
      position: absolute;
      margin-top: 0;
      white-space: nowrap;
    }
  }
}

.list-actions {
  &.horizontal {
    button,
    span {
      &:not(:first-of-type) {
        margin-left: 10px;
      }
    }
  }
}

.nodes-table-cell {
  height: 100%;
  display: flex;
  align-items: center;

  .node-info {
    color: @ninja-dark;

    .node-name {
      font-size: 13px;
      font-weight: 800;
    }

    div:nth-child(2),
    div:nth-child(3) {
      font-size: 10px;
    }

    div:nth-child(3) {
      color: @ninja-medium;
    }
  }
}

.flex-row {
  display: flex;
  flex-flow: row;

  &.spacing-xs {
    > *:not(:first-child) {
      margin-left: 5px;
    }
  }

  &.spacing-sm {
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &.spacing {
    > *:not(:first-child) {
      margin-left: 15px;
    }
  }

  &.spacing-md {
    > *:not(:first-child) {
      margin-left: 20px;
    }
  }

  &.spacing-lg {
    > *:not(:first-child) {
      margin-left: 30px;
    }
  }

  &.spacing-xl {
    > *:not(:first-child) {
      margin-left: 50px;
    }
  }
}

.margin-table {
  margin: 10px;
}

.use-temporary-padding {
  padding: 16px 16px 0px 16px;
}

.not-found-error-heading {
  font-size: 1.375rem;
  line-height: 1.5;
  font-weight: 500;
  color: #151617;
}
