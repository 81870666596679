.open > .vertical-dropdown-menu {
    text-shadow: none;
    z-index: 1004;
    -webkit-animation-duration: .5s;
    -moz-animation-duration: .5s;
    -o-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-name: verticalMenu !important;
    -moz-animation-name: verticalMenu !important;
    -o-animation-name: verticalMenu !important;
    animation-name: verticalMenu !important;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    &.device-details-menu {
      left: auto;
      top: 72px;
      margin-left: -5px;
    }

    &.system-devices-menu {
        // Margin for col-2 for left-panel
        margin-left: 16.66666667%;
        top: 10px;
        left: -70px;
    }

    &.action-menu {
        top: 18px;
    }

    li {
      width: 250px;
    }

    li span {
      border-radius: 3px;
      color: inherit;
      line-height: 25px;
      padding: 7px 24px;
      text-align: left;
      font-weight: normal;
    }

    li a {
        width: 190px;
    }

    .horizontal-submenu .dropdown-menu {
        -webkit-animation-duration: .5s;
        -moz-animation-duration: .5s;
        -o-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-name: horizontalMenu !important;
        -moz-animation-name: horizontalMenu !important;
        -o-animation-name: horizontalMenu !important;
        animation-name: horizontalMenu !important;

        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        -o-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}

@-webkit-keyframes verticalMenu {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        transform: translateY(15px);
    }
}

@-moz-keyframes verticalMenu {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
}

@-o-keyframes verticalMenu {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        -o-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
}

@keyframes verticalMenu {
    0% {
        opacity: 0;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px);
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
}

@-webkit-keyframes horizontalMenu {
    0% {
        opacity: 0;
        left: 88%;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        left: 97%;
    }
}

@-moz-keyframes horizontalMenu {
    0% {
        opacity: 0;
        left: 88%;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        left: 97%;
    }
}

@-o-keyframes horizontalMenu {
    0% {
        opacity: 0;
        left: 88%;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        left: 97%;
    }
}

@keyframes horizontalMenu {
    0% {
        opacity: 0;
        left: 88%;
    }

    75% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
        left: 97%;
    }
}

.dropdown-menu {
    i {
        position: relative;
        left: -7px;
        top: 1px;
        font-size: 1.1em;
    }

    li a:hover, a:focus {
        color: black;
        background-color: @ninja-white;
    }

    li span:hover, span:focus {
      color: black;
      background-color: @ninja-white;
    }

    li {
        cursor: pointer;
        
        button {
            width: 100%;
            border-radius: 3px;
            color: inherit;
            line-height: 25px;
            padding: 7px 12px;
            text-align: left;
            font-weight: 400;
        }

        &:hover {
            button {
                color: black;
                background-color: @ninja-white;
            }
        }
    }

    &.sub-job-menu {
        max-height: 60vh;
        overflow-y: auto;
        > li > .job-link {
            min-width: 190px;
            width: auto;
        }
    }

    &.condition-packs {
        max-height: 60vh;
        overflow-y: scroll;
        left: -150px;
        > li {
            > a {
                width: auto;
                max-width: 310px;
                white-space: initial;
            }
            &:nth-child(even) > a:not(:hover) {
                background-color: @off-white;
            }
        }
    }
}
